import Colors from "../constants/Colors";

function InputDefault({
  onChange,
  value,
  label,
  id,
  name,
  className,

  placeholder,
  type = "text",
  required = false,
  maxLength,
  disabled,
  colorLabel = Colors.background,
  classNameInput = "",
}: any) {
  return (
    <div className={"w-full grid gap-2 mt-4 " + className}>
      <label
        htmlFor="date_time"
        className="text-md pl-1"
        style={{ color: colorLabel }}
      >
        {label}
      </label>
      <input
        id={id}
        name={name}
        type={type}
        maxLength={maxLength}
        disabled={disabled}
        onChange={onChange}
        value={value}
        required={required}
        style={{
          backgroundColor: type === "color" ? value : undefined,
        }}
        className={
          "appearance-none border-y-[1px] border-x-[1px] border-hrc rounded-3xl relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm " +
          classNameInput
        }
        placeholder={placeholder}
      />
    </div>
  );
}

export default InputDefault;
