import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Importe useParams para acessar os parâmetros da URL

import Colors from "../../constants/Colors";

import utils from "../../helpers/utils";
import feedback from "../../services/feedback";
import linksthree from "../../services/linksthree";

function LinkThree() {
  const { id } = useParams();
  const [data, setData] = useState<any>({ buttons: [] });

  const start = async () => {
    setData(await linksthree.getButtons(id));
  };

  useEffect(() => {
    start();
  }, [id]);

  if (!data) {
    return <div>Carregando...</div>;
  }

  const pageStyle = {
    backgroundColor: data.background ?? Colors.background,
    minHeight: "100vh",

    color: data.color_secondary ?? "#FFF",
  } as any;

  const sendfeedback = async (btid: string) => {
    await feedback.send_acess(id, btid);
  };

  const handleButtonClick = async (btid: string, url: string) => {
    sendfeedback(btid);
    if (url.indexOf("http") != -1) {
      window.open(url, "_blank");
    } else {
      window.open(`http://${url}`, "_blank");
    }
  };

  const btn = (ids: string, label: string, url: string, image?: string) => (
    <div
      className="w-full rounded-lg flex justify-between p-2 items-center cursor-pointer mb-2 py-2 hover:py-3 "
      style={{
        backgroundColor: data.color ?? Colors.background,
      }}
      onClick={() => handleButtonClick(ids, url)}
    >
      {image ? (
        <img
          className="w-12 h-[40px] rounded-sm"
          src={image}
          style={{ objectFit: "cover" }}
        />
      ) : null}
      <div className="-ml-12 min-h-[40px] flex items-center justify-center  max-w-[65%]">
        <span
          style={{
            color: data.color_secondary ?? Colors.white,
          }}
          className="font-bold"
        >
          {label}
        </span>
      </div>
      <div></div>
    </div>
  );

  if (!data) return null;

  return (
    <div className="flex items-center" style={pageStyle}>
      <div className="w-full mt-10 text-center flex flex-col justify-center items-center gap-6">
        <div className="flex flex-col gap-4">
          {data.photo ? (
            <img
              src={utils.getUriImage(data.photo ?? "")}
              alt={data.name}
              style={{ objectFit: "cover" }}
              className="rounded-full w-24 h-24 mx-auto"
            />
          ) : null}
          <div className="px-4 lg:px-2">
            <h1 className="font-bold" style={{ color: data?.color }}>
              {data.name}
            </h1>
            {data?.description ? (
              <h6 className="font-bold" style={{ color: data?.color }}>
                {data?.description}
              </h6>
            ) : null}
          </div>
        </div>
        <div className="w-full px-4 lg:px-0 lg:w-1/3 ">
          {data?.buttons
            ? data.buttons.map((buts: any) => (
                <div key={buts.id}>
                  {btn(
                    buts.id,
                    buts.label,
                    buts.url,
                    utils.getUriImage(buts.image)
                  )}
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default LinkThree;
