import { useState, useRef, useEffect } from "react";
import Colors from "../constants/Colors";
import { FaPen } from "react-icons/fa";
import blob from "../services/blob";
import { useAuth } from "../context/AuthContext";
import utils from "../helpers/utils";
import Load from "./Load";

function ImageInput({
  onChange,
  value,
  label,
  id,
  name,
  className,
  placeholder,
  required = false,
  maxLength,
  colorLabel = Colors.background,
  classNameImage = "",
  background = Colors.gray3,
  objectFit = "cover",
  onError,
}: any) {
  const fileInputRef = useRef<any>(null);
  const [myValue, setMyValue] = useState(value);
  const [loading, setLoading] = useState(false);

  const { getUser }: any = useAuth();
  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    setMyValue(value);
  }, [value]);

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      try {
        setLoading(true);
        const base64String = await getBase64(file);
        const base64Data = `${base64String}`.split(",")[1];

        const file_name = await blob.uploadImage(
          getUser().id,
          base64Data,
          file.name
        );

        if (onChange) {
          onChange(`/${getUser().id}/${file_name}`);
          setMyValue(`/${getUser().id}/${file_name}`);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error uploading file:", error);
      }
    }
  };

  return (
    <div className={"w-full grid gap-2 mt-4 " + className}>
      <label
        htmlFor={id}
        className="text-md pl-1"
        style={{ color: colorLabel }}
      >
        {label}
      </label>
      <div className="relative w-full cursor-pointer flex flex-col md:flex-row lg:flex-row gap-4 items-center">
        <img
          src={
            !loading
              ? utils.getUriImage(myValue)
              : "https://upload.wikimedia.org/wikipedia/commons/c/ca/1x1.png"
          }
          onError={() =>
            setMyValue(
              onError ??
                "https://upload.wikimedia.org/wikipedia/commons/c/ca/1x1.png"
            )
          }
          className={
            "w-full md:w-1/2 lg:w-1/2 rounded-md h-[250px] " + classNameImage
          }
          onClick={handleImageClick}
          alt=""
          style={{
            zIndex: 0,
            objectFit: objectFit,
            background: background,

            borderWidth: 1,
            borderColor: Colors.background,
          }}
        />
        <div
          className="absolute top-2 left-2 p-2 bg-background rounded-full shadow-xl cursor-pointer"
          style={{ zIndex: 2 }}
          onClick={handleImageClick}
        >
          {loading ? <Load /> : <FaPen color="white" size={18} />}
        </div>
        <input
          id={id}
          name={name}
          disabled={loading}
          type="file"
          maxLength={maxLength}
          onChange={handleFileChange}
          required={required}
          accept=".jpg,.png"
          ref={fileInputRef}
          className={
            "appearance-none border-y-[1px] border-x-[1px] border-hrc rounded-3xl relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm"
          }
          style={{ display: "none" }}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}

export default ImageInput;
