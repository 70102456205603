import Colors from "../constants/Colors";
import Modal from "react-modal";
import utils from "../helpers/utils";
import Strings from "../constants/Strings";
import { FiPenTool, FiPlus, FiRefreshCw, FiSave, FiX } from "react-icons/fi";
import { useEffect, useState } from "react";
import plans from "../services/plans";
import students from "../services/students";
import { useAuth } from "../context/AuthContext";
import TrainItem from "./TrainItem";
import AvaliationItem from "./AvaliationItem";
import Button from "./Button";
import Load from "./Load";
import BackButton from "./BackButton";
import { usePrescription } from "../context/PrescriptionContext";
import Styles from "../constants/Styles";
import avaliations from "../services/avaliations";
import Chart from "./Chart";
import Card from "./Card";
import InputDefault from "./InputDefault";
import { toast } from "react-toastify";

function StudentModal({ onBack, isOpen, data }: any) {
  const [load, setLoad] = useState(true);
  const [planos, setPlanos] = useState({ validity: null });
  const [treinos, setTreinos] = useState([]);
  const [avaliacao, setAvaliacao] = useState([]);
  const [tracking, setTracking] = useState([]);

  const [vencimento, setVencimento] = useState<boolean | string>(false);

  const { newPrescription, addAvaliation, setWorkout, updateAvaliation }: any =
    usePrescription();
  const { getUser }: any = useAuth();

  const saveRenew = async () => {
    const prof_id = getUser()?.id;
    const res = await students.renew(
      data.id,
      prof_id,
      new Date(vencimento.toString())
    );

    if (res) {
      setPlanos(await plans.get_plan(data.id));
      setVencimento(false);
      toast.success("Validade alterada com sucesso!");
    }
  };

  const getPlans = async (id: string) => {
    setLoad(true);

    const prof_id = getUser()?.id;
    try {
      const [planos, treinos, avaliacao, track] = await Promise.all([
        plans.get_plan(id),
        students.get_all_prescription(prof_id, id),
        avaliations.listall(prof_id, id),
        students.list_all_(id),
      ]);

      setPlanos(planos);
      setTreinos(treinos);
      setAvaliacao(avaliacao);
      setTracking(track);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }

    setLoad(false);
  };

  useEffect(() => {
    setPlanos({ validity: null });
    setTreinos([]);
    if (data && data?.id) getPlans(data.id);
  }, [isOpen]);

  if (!data) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onBack()}
      style={{
        content: { ...Styles.styleModal },
      }}
      contentLabel="Example Modal"
    >
      <div className="grid w-[98vw] sm:w-[98vw] md:w-[100vw] lg:w-[50vw] h-[100vh] sm:h-[100vh] md:h-[100vh] lg:h-[auto] max-h-[98vh] ">
        <div
          className="h-[110px]"
          style={{
            background: `linear-gradient(to left, ${Colors.background},${Colors.background}, ${Colors.background2})`,
          }}
        ></div>
        <img
          src={utils.getUriImage(
            data?.photo !== "" ? data?.photo : Strings.default_photo
          )}
          alt={data?.name ?? ""}
          style={{
            width: 130,
            height: 130,
            borderRadius: 130,
            objectFit: "cover",
            position: "absolute",
            top: 35,
            right: 20,
          }}
          className="shadow-lg"
        />
        <div className="pl-4 pr-4 h-full mt-[80px]">
          <div className="grid gap-1 -mt-[60px]">
            <span
              className="text-4xl font-bold text-start mb-0"
              style={{ color: Colors.gray2 }}
            >
              {data?.name}
            </span>

            <div className="grid sm:grid md:grid lg:flex justify-between mt-[30px] gap-2">
              <span
                className="text-md italic font-normal"
                style={{ color: Colors.gray2 }}
              >
                E-mail: <b>{data?.email}</b>
              </span>
              <span
                className="text-md italic font-normal"
                style={{ color: Colors.gray2 }}
              >
                Entrou em:{" "}
                <b>{utils.formatDate(new Date(data?.registe) ?? new Date())}</b>
              </span>
            </div>

            <div className="grid sm:grid md:grid lg:grid justify-between -ml-[2px] gap-2">
              {data?.phone ? (
                <a
                  className="text-md italic font-normal"
                  target="_blank"
                  href={`https://api.whatsapp.com/send/?phone=${data?.phone}&text&type=phone_number&app_absent=0`}
                >
                  Telefone:{" "}
                  <b
                    style={{ color: "green" }}
                    className="underline italic font-semibold"
                  >
                    {utils.formatarTelefone(data?.phone)}
                  </b>
                </a>
              ) : null}

              {planos?.validity ? (
                <div className="flex items-center gap-2">
                  <div className={vencimento ? "mt-[25px]" : ""}>
                    <span
                      className="text-md italic font-normal"
                      style={{ color: Colors.gray2 }}
                    >
                      Vencimento:
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    {!vencimento ? (
                      <b>
                        {planos?.validity
                          ? utils.formatDate(new Date(planos?.validity))
                          : null}
                      </b>
                    ) : (
                      <InputDefault
                        className="w-[120px] h-[50px] "
                        type="date"
                        required={true}
                        onChange={({ target }: any) => {
                          setVencimento(target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className={vencimento ? "mt-[25px]" : undefined}>
                    {vencimento ? (
                      <div className="flex gap-2">
                        <FiX
                          color={Colors.background}
                          onClick={() => setVencimento(false)}
                          className="cursor-pointer"
                          size={23}
                        />
                        <FiSave
                          color={Colors.background}
                          size={23}
                          onClick={() => saveRenew()}
                          className="cursor-pointer"
                        />
                      </div>
                    ) : (
                      <FiRefreshCw
                        color={Colors.background}
                        className="cursor-pointer"
                        onClick={() => setVencimento("NOT")}
                        size={23}
                      />
                    )}
                  </div>
                </div>
              ) : null}

              {data?.gener ? (
                <span
                  className="text-md italic font-normal"
                  style={{ color: Colors.gray2 }}
                >
                  Gênero: <b>{data?.gener}</b>
                </span>
              ) : null}
            </div>

            <div className="grid mt-2 mb-4">
              {load ? <Load /> : null}
              {!load ? (
                <Card
                  title="Frequência"
                  data={tracking}
                  subtitle=" (últimos 7 dias)"
                  className="lg:w-[100%] p-2 mt-2"
                >
                  <Chart
                    sliceNum={-7}
                    sufix="%"
                    data={tracking.map((e: any) => {
                      return {
                        ...e,
                        count: e.count != 0 ? 100 : 0,
                      };
                    })}
                  />
                </Card>
              ) : null}
            </div>

            <div className="grid p-2">
              <h4
                style={{ color: Colors.background }}
                className="font-bold italic text-xl"
              >
                Plano de Treino:
              </h4>
              <div className="justify-between gap-3 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-2 p-1">
                {load ? <Load /> : null}
                {!load &&
                  treinos.map((e: any) => (
                    <TrainItem data={e} onClick={(a: any) => setWorkout(a)} />
                  ))}

                <div className="w-16">
                  <Button
                    className="py-[11px]"
                    onClick={() => newPrescription()}
                  />
                </div>
              </div>
            </div>
            <div className="grid mt-4 p-2">
              <h4
                style={{ color: Colors.background }}
                className="font-bold italic text-xl"
              >
                Avaliações:
              </h4>

              <div className="justify-between gap-3 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-2 p-2">
                {load ? <Load /> : null}
                {!load &&
                  avaliacao.map((e: any) => (
                    <AvaliationItem
                      data={e}
                      onClick={() => updateAvaliation(e.avaliation, e.id)}
                    />
                  ))}
                <div className="w-16">
                  <Button
                    className="py-[11px]"
                    onClick={() => addAvaliation(data.id, getUser()?.id)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-0 p-4 w-full flex justify-end max-h-[85px]">
          <BackButton
            title="Voltar"
            className="w-full sm:w-full md:w-w-full lg:w-1/6 py-2"
            onBack={onBack}
          />
        </div>
      </div>

      <div className="pl-4 pr-4 grid "></div>
    </Modal>
  );
}
export default StudentModal;
