import Modal from "react-modal";
import Styles from "../../constants/Styles";
import HeaderTitle from "../../components/HeaderTitle";
import InputDefault from "../../components/InputDefault";
import { useEffect, useState } from "react";
import BottomModal from "../../components/BottomModal";
import students from "../../services/students";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";
import utils from "../../helpers/utils";

const initState = {
  name: "",
  photo: "",
  email: "",
  password: "",
  birth_date: "1990-05-15T00:00:00.000Z",
  students: [],
  phone: "",
  registe: null,
  validity: null,
  professional: "",
};

function NewStudentModal({ isOpen, onBack }: any) {
  const [form, setForm] = useState(initState);
  const { getUser }: any = useAuth();

  async function submit(e: any) {
    e.preventDefault();

    const data = await students.create({
      ...form,

      professional_id: getUser()?.id,
    });

    if (data) {
      toast.success(
        "Aluno cadastrado com sucesso! A senha foi enviada por e-mail."
      );
      onBack();
    } else {
      toast.error(
        "Ouve um problema ao cadastrar o aluno, por favor, tente novamente."
      );
    }
  }

  useEffect(() => {
    setForm(initState);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onBack()}
      style={{
        content: Styles.styleModal,
      }}
      contentLabel="Example Modal"
    >
      <form
        onSubmit={submit}
        className="grid w-[98vw] sm:w-[95vw] md:w-[95vw] lg:w-[50vw] h-[80vh] sm:h-[80vh] md:h-[80vh] lg:h-[auto] p-4 mb-6"
      >
        <div className="flex w-full">
          <div className="container">
            <HeaderTitle title="Novo Aluno" />
            <hr className="mt-4" />
            <div className="w-full h-full">
              <div className="mb-8 sm:mb-8 md:mb-8 lg:mb-0 w-full mt-4 ">
                <InputDefault
                  label="Nome do Aluno"
                  id="name"
                  name="name"
                  type="text"
                  value={form.name}
                  required={true}
                  onChange={({ target }: any) => {
                    setForm({ ...form, [target.name]: target.value });
                  }}
                />

                <InputDefault
                  label="E-mail"
                  id="email"
                  name="email"
                  type="email"
                  value={form.email}
                  required={true}
                  onChange={({ target }: any) => {
                    setForm({ ...form, [target.name]: target.value });
                  }}
                />

                <InputDefault
                  label="Data de Nascimento"
                  id="birth_date"
                  name="birth_date"
                  type="date"
                  value={form.birth_date}
                  required={true}
                  onChange={({ target }: any) => {
                    setForm({ ...form, [target.name]: target.value });
                  }}
                />

                <InputDefault
                  label="Telfone de contato"
                  id="phone"
                  name="phone"
                  maxLength={15}
                  type="text"
                  value={utils.formatPhoneNumber(form.phone)}
                  required={true}
                  onChange={({ target }: any) => {
                    setForm({ ...form, [target.name]: target.value });
                  }}
                />

                <InputDefault
                  label="Validade do Plano"
                  id="validity"
                  name="validity"
                  type="date"
                  value={form.validity}
                  required={true}
                  onChange={({ target }: any) => {
                    setForm({ ...form, [target.name]: target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <BottomModal
          type="submit"
          onBack={onBack}
          style={{
            marginBottom: 10,
          }}
          // id={form?.id}
          // onDelete={deleteWorkout}
        />
      </form>
    </Modal>
  );
}
export default NewStudentModal;
