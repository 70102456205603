import { FiCalendar, FiEye, FiEyeOff, FiLink, FiUser } from "react-icons/fi";
import Card from "../../components/Card";
import MenuLayout from "../../components/Menu";
import Colors from "../../constants/Colors";
import { useEffect, useState } from "react";
import appointment from "../../services/appointment";
import { useAuth } from "../../context/AuthContext";
import utils from "../../helpers/utils";
import reports from "../../services/reports";
import AppointmentItem from "../../components/AppointmentItem";
import Chart from "../../components/Chart";
import StudentModal from "../../components/StudentModal";
import { usePrescription } from "../../context/PrescriptionContext";
import HeaderTitle from "../../components/HeaderTitle";

import linksthree from "../../services/linksthree";

function Home() {
  const [view, setView] = useState(true);
  const [dash, setDash] = useState([]);
  const [aulas, setAulas] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [acessos, setAcessos] = useState([]);
  const { setUserModal }: any = usePrescription();

  const { getUser }: any = useAuth();
  const user = getUser();

  const init = async () => {
    setAppointments(await appointment.getWeekAppointment(user.id));
  };

  const dashboard = async () => {
    setDash(await reports.crescimentoUsers(user.id));

    setAulas(await reports.getDashAulas(user.id));

    const myViews = await linksthree.getButtons(user.id);

    setAcessos(
      myViews?.buttons?.map((e: any) => {
        return {
          ...e,
          date: e.label,
          count: e.clicks,
        };
      }) ?? []
    );
  };

  useEffect(() => {
    init();
    dashboard();
  }, []);

  return (
    <MenuLayout>
      <div className="flex w-full justify-center">
        <div className="container justify-start">
          <div className="flex justify-between items-center">
            <HeaderTitle title="Dashboard" />

            <div className="cursor-pointer" onClick={() => setView(!view)}>
              {view ? (
                <FiEye fontSize={20} color={Colors.background} />
              ) : (
                <FiEyeOff fontSize={20} color={Colors.background} />
              )}
            </div>
          </div>
          {view ? (
            <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row gap-6 sm:gap-4 mt-4">
              <Card title="Aulas" icon={<FiCalendar className={`h-4 w-4`} />}>
                <Chart data={aulas} />
              </Card>
              <Card title="Alunos" icon={<FiUser className={`h-4 w-4`} />}>
                <Chart data={dash} />
              </Card>
              <Card title="Acessos" icon={<FiLink className={`h-4 w-4`} />}>
                <Chart data={acessos} />
              </Card>
            </div>
          ) : null}

          <h4
            style={{ color: Colors.background }}
            className="font-bold italic text-xl mt-8"
          >
            Próximos Atendimentos
          </h4>

          {appointments.length === 0 ? (
            <center>
              <h5 style={{ color: Colors.background }} className="mt-8">
                Nenhum atendimento encontrado.
              </h5>
            </center>
          ) : null}

          <div className="mt-4 justify-between gap-4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {appointments.map((e: any) => (
              <AppointmentItem
                data={e}
                onClick={(data: any) => setUserModal(data?.user)}
              />
            ))}
          </div>
        </div>
      </div>
    </MenuLayout>
  );
}

export default Home;
