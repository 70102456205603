import api from "./api";

async function update_plan(plans_id: string, body: any) {
  try {
    const { data } = await api.put(`/workout-plans/${plans_id}`, body);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function create_plan(body: any) {
  try {
    const { data } = await api.post(`/workout-plans`, body);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function delete_plan(plans_id: string) {
  try {
    const { data } = await api.delete(`/workout-plans/${plans_id}`);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export default { update_plan, delete_plan, create_plan };
