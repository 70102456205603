import Modal from "react-modal";
import Colors from "../constants/Colors";
import { useEffect, useState } from "react";
import Button from "./Button";
import FichaItem from "./FichaItem";
import BackButton from "./BackButton";
import { FiSave } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import BottomModal from "./BottomModal";
import { usePrescription } from "../context/PrescriptionContext";
import InputDefault from "./InputDefault";
import Styles from "../constants/Styles";
import HeaderTitle from "./HeaderTitle";
import exerciciesModal from "../services/exercicies";
import { useAuth } from "../context/AuthContext";

function FichaModel({ isOpen, data, onBack }: any) {
  const [form, setForm] = useState<any>(null);
  const { getUser }: any = useAuth();
  const {
    setExercicie,
    workout,
    addExercicie,
    removeExercicie,
    updateFicha,
  }: any = usePrescription();

  const [exercicies, setExercicies] = useState<any>([]);

  const init = async () => {
    setExercicies(await exerciciesModal.list_all(getUser()?.id, 1, 10));
  };

  const save = () => {
    updateFicha(form);
    onBack();
  };

  const getName = (id: string) => {
    try {
      const sd = exercicies.filter((e: any) => e.id === id);

      if (sd.length > 0) {
        return sd[0].name;
      } else {
        return "";
      }
    } catch {
      return "";
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setForm({
      name: form?.name ?? data?.name,
      duration: form?.duration ?? data?.duration,
      intensity: form?.intensity ?? data?.intensity,
      ...data,
    });
  }, [isOpen, workout]);

  if (!form) {
    return null;
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onBack()}
      style={{
        content: Styles.styleModal,
      }}
      contentLabel="Example Modal"
    >
      <div className="max-h-[100vh]">
        <HeaderTitle title="Ficha" className="pl-4 pt-4" />
        <hr className="mt-4" />
        <form
          onSubmit={(e) => {}}
          className="grid w-[98vw] sm:w-[98vw] md:w-[100vw] lg:w-[50vw] h-[auto] sm:h-[auto] md:h-[auto] lg:h-[auto] p-4"
        >
          <div className="flex w-full">
            <div className="container">
              <div className="w-full h-full">
                <div className="mb-8 sm:mb-8 md:mb-8 lg:mb-0 w-full mt-4 ">
                  <InputDefault
                    label="Nome do Ficha"
                    id="name"
                    name="name"
                    type="text"
                    value={form.name}
                    required={true}
                    onChange={({ target }: any) => {
                      setForm({ ...form, [target.name]: target.value });
                    }}
                    placeholder="Leg Press..."
                  />

                  <InputDefault
                    label="Duração"
                    id="duration"
                    name="duration"
                    type="text"
                    value={form.duration}
                    required={true}
                    onChange={({ target }: any) => {
                      setForm({ ...form, [target.name]: target.value });
                    }}
                    placeholder="60 minutos"
                  />

                  <div className="w-full grid gap-2 mt-4">
                    <label
                      htmlFor="date_time"
                      className="text-md pl-1"
                      style={{ color: Colors.background }}
                    >
                      Intencidade
                    </label>
                    <select
                      id="intensity"
                      name="intensity"
                      onChange={({ target }) => {
                        setForm({ ...form, [target.name]: target.value });
                      }}
                      autoComplete="intensity"
                      required
                      value={form.intensity}
                      className="appearance-none border-y-[1px] border-x-[1px] border-hrc rounded-3xl relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm"
                    >
                      <option disabled selected />
                      <option value="Alta">Alta</option>
                      <option value="Média">Média</option>
                      <option value="Baixa">Baixa</option>
                    </select>
                  </div>
                </div>
                <div className="mt-6">
                  <h4
                    style={{ color: Colors.background }}
                    className="font-bold italic text-xl"
                  >
                    Exercícios:
                  </h4>

                  <div className="justify-between gap-3 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 mt-2 p-1 ">
                    {form?.exercises?.map((c: any) => (
                      <FichaItem
                        data={c}
                        nameLabel={getName(c.id)}
                        onDelete={() => removeExercicie(c.mid)}
                        onClick={(a: any) => setExercicie(a)}
                        label={`Séries: ${c.sets}  x   Repetições: ${c.reps}`}
                      />
                    ))}
                    <Button
                      title=""
                      className="w-1/4 h-[55px] "
                      onClick={() => addExercicie()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="pb-2 p-4 pt-2">
          <BottomModal onSave={save} onBack={onBack} id={form?.id} />
        </div>
      </div>
    </Modal>
  );
}

export default FichaModel;
