import { useEffect, useState } from "react";
import Modal from "react-modal";
import { FiPlus, FiSave, FiX } from "react-icons/fi";
import Colors from "../constants/Colors";
import BackButton from "./BackButton";
import { useAuth } from "../context/AuthContext";
import exercicies from "../services/exercicies";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import InputDefault from "./InputDefault";
import Styles from "../constants/Styles";
import HeaderTitle from "./HeaderTitle";
import BottomModal from "./BottomModal";
import ImageInput from "./ImageInput";
import Strings from "../constants/Strings";
import utils from "../helpers/utils";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

function ExercicieModal({ isOpen, onBack, data }: any) {
  const { getUser }: any = useAuth();
  const professional_id = getUser()?.id;
  const [form, setForm] = useState({
    name: "",
    image: "",
    professional_id,
    video: "",
    description: "",
    id: null,
  });

  const deleteItem = async () => {
    if (!form.id) return;
    const resp = await exercicies.deleteItem(form.id);

    if (resp) {
      toast.success("Exercício removido com sucesso!");
      onBack();
    } else {
      toast.error(
        "Ouve um problema ao remover o exercicio, por favor, tente novamente."
      );
    }
  };

  const submit = async (e: any) => {
    e.preventDefault();
    if (form.id !== null) {
      const udp = await exercicies.update(form.id, form);
      if (udp) {
        toast.success("Exercício atualizado com sucesso!");
        onBack();
      } else {
        toast.error(
          "Erro ao atualizar o exercício, por favor, tente novamente."
        );
      }
    } else {
      const udp = await exercicies.create(form);
      if (udp) {
        toast.success("Exercício atualizado com sucesso!");
        onBack();
      } else {
        toast.error(
          "Erro ao atualizar o exercício, por favor, tente novamente."
        );
      }
    }
  };

  useEffect(() => {
    if (data) setForm({ ...form, id: null, professional_id, ...data });
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onBack()}
      style={{
        content: Styles.styleModal,
      }}
      contentLabel="Example Modal"
    >
      <form
        onSubmit={(e) => submit(e)}
        className="grid w-[98vw] sm:w-[98vw] md:w-[100vw] lg:w-[50vw] max-h-[90vh] md:max-h-[90vh] lg:max-h-[98vh] p-4"
      >
        <div className="flex w-full">
          <div className="container">
            <HeaderTitle title="Exercício" />
            <hr className="mt-4" />
            <div className="w-full h-full">
              <div className="mb-8 sm:mb-8 md:mb-8 lg:mb-0 w-full mt-4 ">
                <InputDefault
                  label="Nome do Exercício"
                  id="name"
                  name="name"
                  type="text"
                  value={form.name}
                  required={true}
                  onChange={({ target }: any) => {
                    setForm({ ...form, [target.name]: target.value });
                  }}
                />
                <ImageInput
                  label="Imagem"
                  id="image"
                  name="image"
                  classNameImage="h-[300px]"
                  objectFit="contain"
                  value={utils.getUriImage(
                    form.image ?? Strings.default_photo,
                    "all_view"
                  )}
                  required={true}
                  onChange={(value: string) => {
                    setForm({ ...form, image: value });
                  }}
                />

                <InputDefault
                  label="Descrição"
                  id="description"
                  name="description"
                  type="text"
                  value={form.description}
                  onChange={({ target }: any) => {
                    setForm({ ...form, [target.name]: target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <BottomModal
          onBack={onBack}
          id={form?.id}
          onDelete={deleteItem}
          type="submit"
        />
      </form>
    </Modal>
  );
}

export default ExercicieModal;
