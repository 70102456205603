import Colors from "../constants/Colors";
import Strings from "../constants/Strings";
import utils from "../helpers/utils";

function AppointmentItem({ data, onClick }: any) {
  return (
    <div
      className="rounded-2xl p-4 flex items-center gap-2 select-none cursor-pointer w-full shadow-lg"
      style={{
        background: `linear-gradient(to left, ${Colors.background},${Colors.background},${Colors.background},${Colors.background}, ${Colors.background2})`,
      }}
      onClick={() => onClick(data)}
    >
      <img
        src={utils.getUriImage(
          data?.user?.photo != "" ? data?.user?.photo : Strings.default_photo
        )}
        style={{ width: 70, height: 70, objectFit: "cover" }}
        className="rounded"
      />
      <div className="flex flex-col justify-between gap-1">
        <span className="font-bold">{data?.user?.name}</span>
        <div className="flex flex-col">
          <span className="italic font-light text-sm">
            {utils.formatDate(new Date(data?.start_time))}
          </span>
          <span className="italic font-light text-sm mt-1">
            {utils.formatTime(new Date(data?.start_time))} -{" "}
            {utils.formatTime(new Date(data?.end_time))}
          </span>
        </div>
      </div>
    </div>
  );
}

export default AppointmentItem;
