import { FaSave } from "react-icons/fa";
import Colors from "../constants/Colors";

import utils from "../helpers/utils";

import { MdClose, MdDelete, MdEdit } from "react-icons/md";
import { useEffect, useState } from "react";
import ImageInput from "./ImageInput";
import InputDefault from "./InputDefault";
import Button from "./Button";

function LinkButtonItem({ data, onClick, onSubmit, onRemove }: any) {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(data);

  useEffect(() => {
    if (open) {
      setForm(data);
    }
  }, [open]);

  useEffect(() => {
    if (data.open) {
      setOpen(true);
    }
  }, [data]);

  const submit = (e: any) => {
    e.preventDefault();
    onSubmit({ ...form, open: null });
    setOpen(false);
    setForm({});
  };

  const styles: any = {
    background: `linear-gradient(to left, ${Colors.background},${Colors.background},${Colors.background},${Colors.background}, ${Colors.background2})`,
  };

  return (
    <div
      onClick={() => (onClick ? onClick(data) : null)}
      className="rounded-2xl p-4 gap-2 select-none w-full shadow-lg pb-4"
      style={styles}
    >
      <div className="flex justify-between w-full">
        <div
          className="flex flex-row justify-between gap-4 py-1 cursor-pointer items-center"
          onClick={() => setOpen(!open)}
        >
          <img
            src={utils.getUriImage(data?.image ?? "")}
            className="w-12 h-12 object-cover bg-white rounded-sm"
          />
          <span className="font-bold text-base flex items-center gap-4 min-w-12">
            {data.label}
          </span>
        </div>
        <div className="flex flex-row gap-4 pr-2 items-center ml-4">
          {!open ? (
            <MdEdit
              size={20}
              onClick={() => setOpen(!open)}
              className="cursor-pointer"
            />
          ) : (
            <>
              <MdDelete
                size={20}
                className="cursor-pointer"
                onClick={() => onRemove(data.id)}
              />

              <MdClose
                onClick={() => setOpen(!open)}
                size={20}
                className="cursor-pointer"
              />
            </>
          )}
        </div>
      </div>
      {open ? (
        <form className="grid gap-8" onSubmit={submit}>
          <div className="flex flex-col lg:flex-row  justify-center items-center gap-6">
            <div className="w-full lg:w-1/2">
              <ImageInput
                id="image"
                classNameImage="lg:h-[145px] lg:w-full"
                name="image"
                objectFit="contain"
                colorLabel={Colors.white}
                value={utils.getUriImage(form.image ?? "")}
                onChange={(value: string) => setForm({ ...form, image: value })}
              />
            </div>
            <div className="w-[100%]">
              <InputDefault
                colorLabel={Colors.white}
                className="lg:mt-4"
                required={true}
                label="Titulo:"
                value={form.label}
                onChange={(e: any) =>
                  setForm({ ...form, label: e.target.value })
                }
              />
              <InputDefault
                colorLabel={Colors.white}
                label="URL:"
                className="lg:mt-1"
                value={form.url}
                required={true}
                onChange={(e: any) => setForm({ ...form, url: e.target.value })}
              />
            </div>
          </div>
          <Button
            className="py-[12px] border-solid border-[1px] border-gray-600 "
            icon={<FaSave size={18} />}
            type="submit"
          />
        </form>
      ) : null}
    </div>
  );
}

export default LinkButtonItem;
