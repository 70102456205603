import api from "./api";

async function uploadImage(
  id_professional: string,
  base64: string,
  fileName: string
) {
  try {
    const body = {
      base64,
      file_name: fileName,
    };
    const { data } = await api.post(`/upload/${id_professional}`, body);
    return data.file_name;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export default {
  uploadImage,
};
