import { useEffect, useState } from "react";
import Modal from "react-modal";
import { FiPlus, FiSave, FiX } from "react-icons/fi";
import Colors from "../constants/Colors";
import BackButton from "./BackButton";
import { useAuth } from "../context/AuthContext";
import exercicies from "../services/exercicies";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import Button from "./Button";
import { usePrescription } from "../context/PrescriptionContext";
import workoutplans from "../services/workoutplans";
import FichaItem from "./FichaItem";
import BottomModal from "./BottomModal";
import InputDefault from "./InputDefault";
import Styles from "../constants/Styles";
import HeaderTitle from "./HeaderTitle";
import utils from "../helpers/utils";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

function WorkoutModal({ isOpen, onBack, data, user_id = null }: any) {
  const { getUser }: any = useAuth();
  const {
    INIT_WORK,
    userModal,
    setFichaId,
    addFicha,
    submitWorkout,
    removeFichaId,
  }: any = usePrescription();
  const [form, setForm] = useState({
    ...INIT_WORK,
    user_id: userModal?.id,
    professional_id: getUser()?.id,
  });

  useEffect(() => {
    if (isOpen) {
      setForm(
        data ?? {
          ...INIT_WORK,
          user_id: userModal?.id,
          professional_id: getUser()?.id,
        }
      );
    }
  }, [isOpen]);

  const deleteWorkout = async () => {
    if (!form?.id) return;
    const rep = await workoutplans.delete_plan(form.id);
    if (rep) {
      toast.success("Plano de Treino removido com sucesso!");
      onBack();
    }
  };

  const submit = async (e: any) => {
    e.preventDefault();
    await submitWorkout(form);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onBack()}
      style={{
        content: Styles.styleModal,
      }}
      contentLabel="Example Modal"
    >
      <div className="max-h-[100vh]">
        <HeaderTitle title="Plano de Treino" className="pl-4 pt-4" />
        <hr className="mt-4" />
        <form
          onSubmit={submit}
          className="grid w-[98vw] sm:w-[98vw] md:w-[100vw] lg:w-[50vw] h-[auto] sm:h-[auto] md:h-[auto] lg:h-[auto] p-4"
        >
          <div className="flex w-full">
            <div className="container">
              <div className="w-full h-full">
                <div className="mb-8 sm:mb-8 md:mb-8 lg:mb-0 w-full mt-4 ">
                  <InputDefault
                    label="Nome do Plano"
                    id="plan_name"
                    name="plan_name"
                    type="text"
                    value={form.plan_name}
                    required={true}
                    onChange={({ target }: any) => {
                      setForm({ ...form, [target.name]: target.value });
                    }}
                  />

                  <InputDefault
                    label="Objetivo"
                    id="objective"
                    name="objective"
                    type="text"
                    value={form.objective}
                    required={true}
                    onChange={({ target }: any) => {
                      setForm({ ...form, [target.name]: target.value });
                    }}
                    placeholder="Ganho de massa magra..."
                  />

                  <div className="flex flex-row justify-between gap-4">
                    <InputDefault
                      label="Início"
                      id="start_date"
                      name="start_date"
                      type="date"
                      value={form.start_date}
                      required={true}
                      onChange={({ target }: any) => {
                        setForm({ ...form, [target.name]: target.value });
                      }}
                      placeholder="Ganho de massa magra..."
                    />

                    <InputDefault
                      label="Fim"
                      id="end_date"
                      name="end_date"
                      type="date"
                      value={form.end_date}
                      required={true}
                      onChange={({ target }: any) => {
                        setForm({ ...form, [target.name]: target.value });
                      }}
                    />
                  </div>
                  <div className="mt-6">
                    <h4
                      style={{ color: Colors.background }}
                      className="font-bold italic text-xl"
                    >
                      Fichas:
                    </h4>

                    <div className="justify-between gap-3 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 mt-2 p-1">
                      {data?.prescriptions?.map((c: any) => (
                        <FichaItem
                          data={c}
                          iconVariant={true}
                          onDelete={(a: any) => removeFichaId(a.id)}
                          onClick={(a: any) => setFichaId(a.id)}
                        />
                      ))}
                      <Button
                        title=""
                        className="w-1/4"
                        onClick={(a: any) => addFicha(utils.generateId())}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="pb-2 p-4 pt-2">
          <BottomModal
            type="submit"
            onBack={onBack}
            id={form?.id}
            onDelete={deleteWorkout}
            onSave={submit}
          />
        </div>
      </div>
    </Modal>
  );
}

export default WorkoutModal;
