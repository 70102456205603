import api from "./api";

async function getButtons(id: any) {
  try {
    const { data } = await api.get(`/professional-url/${id}`);

    return data;
  } catch (e) {
    return { buttons: [] };
  }
}

async function putButton(id: any, button: any) {
  try {
    const { data } = await api.put(`/professional-url/${id}`, button);

    return data;
  } catch (e) {
    return { buttons: [] };
  }
}

async function putSave(id: any, body: any) {
  try {
    const { data } = await api.put(`/profissionals/${id}`, body);
    return true;
  } catch (e) {
    return false;
  }
}

export default { getButtons, putButton, putSave };
