const white = "#FFFFFF";
const gray = "#504f4f";
const black = "#6253D4";
const black2 = "#7941d4";
const dark = "rgb(37, 23, 64)";
const green = "#2CB547";
const purple = "#92408A";
const yellow = "#F2E205";

export default {
  primary: dark,
  bg_white_default: "rgb(231, 231, 231)",
  background: black,
  background2: black2,
  background3: "#c7c2f03b",
  accent: purple,
  onAccent: black,
  text: white,
  white: white,
  yellow,
  border: gray,
  gray,
  gray2: "#3F3F3F",
  gray3: "rgba(255, 255, 255, 0.75)",
  hr: "rgb(202, 202, 202)",
  variant_dash: "#C6B6F4",
  black: "#020202",
  danger: "#e62e00",
};
