import { useState } from "react";
import Texts from "../../constants/Texts";
import { useAuth } from "../../context/AuthContext";
import Colors from "../../constants/Colors";
import img from "../../assets/ic1.png";
import utils from "../../helpers/utils";
import Load from "../../components/Load";

const LoginPage = () => {
  const { login }: any = useAuth();
  const [load, setLoad] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoad(true);
    await login(email, password);
    setLoad(false);
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center px-4 sm:px-6 "
      style={{
        background:
          "linear-gradient(to right, " +
          Colors.background2 +
          ", " +
          Colors.background +
          ")",
      }}
    >
      <div className="max-w-md w-full">
        <center>
          <img src={utils.getUriImage(img)} style={{ width: 150 }} />
        </center>
        <form className="space-y-10" onSubmit={handleSubmit}>
          <br />
          <br />
          <div className="">
            <div>
              <label htmlFor="email-address" className="sr-only">
                {Texts.email_end}
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-3xl relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm"
                placeholder="Endereço de e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mt-1">
              <label htmlFor="password" className="sr-only">
                {Texts.password}
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-3xl relative block w-full px-3 py-2  placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm"
                placeholder="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="bg-gradient-to-r from-blue-500 to-green-500 w-full flex justify-center py-2 px-4 rounded-3xl font-bold italic"
              style={{
                color: Colors.white,
                background:
                  "linear-gradient(to right, " +
                  Colors.gray2 +
                  ", " +
                  Colors.black +
                  ")",
              }}
              disabled={load}
            >
              {!load ? (
                Texts.entrar
              ) : (
                <Load className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" />
              )}
            </button>
          </div>
          <div className="flex items-center justify-center">
            <div className="text-sm text-white">
              <a
                href="#"
                className="text-white"
                style={{ color: Colors.white }}
              >
                {Texts.esqueceu_senha}
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
