import api from "./api";

async function send_acess(id: any, btid: string) {
  try {
    const { data } = await api.get(
      `/professional-url/add-clicks/${id}/${btid}`
    );
    return data;
  } catch (e) {
    return {};
  }
}

export default { send_acess };
