import api from "./api";

async function create(body: any) {
  try {
    const { data } = await api.post(`/avaliations`, body);
    return data;
  } catch (e) {
    return false;
  }
}

async function update(body: any) {
  try {
    const { data } = await api.put(`/avaliations/${body.id}`, body);
    return data;
  } catch (e) {
    return false;
  }
}

async function listall(professional_id: string, user_id: string) {
  try {
    const { data } = await api.get(
      `/avaliations/${professional_id}/${user_id}`
    );
    return data;
  } catch (e) {
    return [];
  }
}

export default {
  create,
  update,
  listall,
};
