import utils from "../helpers/utils";

function Chart({ data = [], sliceNum = -6, sufix = "" }: any) {
  return (
    <div className="flex gap-4 flex-row h-full select-none pl-8 pr-8 sm:pl-2 sm:pr-2">
      {utils
        .addArray(data, { count: 0, date: "N/A" })
        .slice(sliceNum)
        .map((ds: any, index: number) => {
          if (ds.count === 0) {
            ds.count = 0.2;
          }
          const sd = 100 - ds.count * 15;
          return (
            <div
              className="w-1/6"
              style={{ height: 130 }}
              title={`${ds.date} - ${ds.count ?? 0}`}
            >
              <div
                style={{
                  maxHeight: 100,
                  height: ds.count * 15,
                  marginTop: sd < 0 ? 0 : sd,
                }}
                className={
                  index === data.length - 1
                    ? "bg-white rounded-2xl"
                    : "bg-whiteoff rounded-2xl"
                }
              >
                <center>
                  {ds.count > 1 ? (
                    <div
                      title={`${ds.date} - ${ds.count ?? 0}`}
                      className={
                        ds.count > 1 && ds.count < 2 ? "pt-2" : "pt-[2px]"
                      }
                    >
                      <span
                        className="font-bold text-sm"
                        style={{
                          color:
                            index === data.length - 1 ? "#c6b6f4" : "white",
                        }}
                      >
                        {ds.count}
                        {sufix}
                      </span>
                    </div>
                  ) : null}
                </center>
              </div>
              <div className="items-center justify-center mt-1">
                <center>
                  <span
                    className="mt-1 text-xs italic font-extralight line-clamp-1"
                    title={`${ds.date} - ${ds.count ?? 0}`}
                  >
                    {ds.date}
                  </span>
                </center>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default Chart;
