import { FiSearch } from "react-icons/fi";
import Button from "../../components/Button";
import HeaderTitle from "../../components/HeaderTitle";
import InputDefault from "../../components/InputDefault";
import MenuLayout from "../../components/Menu";
import { DefaultTable } from "../../components/Table";
import Colors from "../../constants/Colors";
import { useEffect, useState } from "react";
import Load from "../../components/Load";
import reports from "../../services/reports";
import { useAuth } from "../../context/AuthContext";
import utils from "../../helpers/utils";

function Reports() {
  const { getUser }: any = useAuth();
  const [form, setForm] = useState({
    relatorio: null,
    start_date: null,
    end_date: null,
    professional_id: getUser()?.id,
  });
  const [load, setLoad] = useState(false);
  const [relatorios, setRelatorios] = useState([]);
  const [data, setData] = useState([]);

  const submit = async (e: any) => {
    e.preventDefault();
    setLoad(true);
    const resp = await reports.genReport(form);
    setData(
      resp.map((f: any) => {
        return {
          ...f.additional_data,
          validity: f.additional_data.validity
            ? utils.formatDate(new Date(f.additional_data.validity))
            : null,

          date: f.additional_data.date
            ? utils.formatDate(new Date(f.additional_data.date))
            : null,
        };
      })
    );
    setLoad(false);
  };

  const start = async () => {
    setRelatorios(await reports.getReports());
  };

  useEffect(() => {
    start();
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <MenuLayout>
      <div className="flex justify-center">
        <div className="container justify-start">
          <HeaderTitle title="Relatórios" />
          <div className="mt-8 justify-between flex flex-col lg:flex-row gap-2 lg:gap-8">
            <form
              onSubmit={submit}
              className="w-full lg:w-1/2 mb-8 sm:mb-8 md:mb-8 lg:mb-0"
            >
              <div>
                <label
                  htmlFor="date_time"
                  className="text-md pl-1"
                  style={{ color: Colors.background }}
                >
                  Relatório
                </label>
                <select
                  id="action_id"
                  name="action_id"
                  onChange={({ target }) =>
                    setForm({ ...form, [target.name]: target.value })
                  }
                  required
                  className="appearance-none border-y-[1px] mt-1 border-x-[1px] border-hrc rounded-3xl relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm"
                >
                  <option disabled selected>
                    ----------------------
                  </option>
                  {relatorios.map((e: any) => (
                    <option value={e.id}>{e.label}</option>
                  ))}
                </select>
              </div>
              <div className="grid">
                <InputDefault
                  label="Data de Início"
                  id="start_date"
                  name="start_date"
                  type="date"
                  required={true}
                  onChange={({ target }: any) =>
                    setForm({ ...form, [target.name]: target.value })
                  }
                />

                <InputDefault
                  label="Data de Fim"
                  id="end_date"
                  name="end_date"
                  type="date"
                  required={true}
                  onChange={({ target }: any) =>
                    setForm({ ...form, [target.name]: target.value })
                  }
                />
              </div>
              <div className="mt-4">
                <Button
                  icon={<FiSearch size={20} />}
                  className="py-[11px]"
                  type="submit"
                />
              </div>
            </form>
            <div className="w-full mt-4">
              {load ? (
                <center>
                  <Load />
                </center>
              ) : data.length > 0 ? (
                <DefaultTable data={data} className="max-h-[70vh]" />
              ) : (
                <span
                  className="text-md pl-2 italic"
                  style={{ color: Colors.background2 }}
                >
                  Sem dados.
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </MenuLayout>
  );
}

export default Reports;
