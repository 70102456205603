import Colors from "../constants/Colors";

function Card({ title, data, children, className, subtitle, icon }: any) {
  return (
    <div
      className={
        "sm:w-full md:w-full lg:w-1/3 rounded-2xl p-4 shadow-lg " + className
      }
      style={{
        background: `linear-gradient(to left, ${Colors.background},${Colors.background},${Colors.background},${Colors.background}, ${Colors.background2})`,
      }}
    >
      <h5 className="font-bold italic text-xl select-none flex items-center gap-2">
        {icon}
        {title}
        {subtitle ? (
          <span className="text-xs font-light">{subtitle}</span>
        ) : null}
      </h5>

      <div className="mt-4">{children}</div>
    </div>
  );
}

export default Card;
