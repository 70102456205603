import { useEffect, useState } from "react";
import MenuLayout from "../../components/Menu";
import Calendar from "react-calendar";
import Colors from "../../constants/Colors";
import { useAuth } from "../../context/AuthContext";
import utils from "../../helpers/utils";
import Modal from "react-modal";
import { FiPlus, FiSave, FiX } from "react-icons/fi";
import api from "../../services/api";
import students from "../../services/students";
import Texts from "../../constants/Texts";
import appointment from "../../services/appointment";
import { toast } from "react-toastify";
import BackButton from "../../components/BackButton";
import InputDefault from "../../components/InputDefault";
import Styles from "../../constants/Styles";
import BottomModal from "../../components/BottomModal";
import HeaderTitle from "../../components/HeaderTitle";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const initform = {
  date_time: new Date().toISOString(),
  duration_minutes: 0,
  type: "",
};
function NewApppointment({
  isOpen,
  onBack,
  idStudent = null,
  onSubmit = () => {},
}: any) {
  const { getUser }: any = useAuth();
  const professional_id = getUser()?.id;

  const [selectedDate, setSelectedDate] = useState<string>(
    new Date().toISOString()
  );
  const [alunos, setAlunos] = useState([]);
  const [form, setForm] = useState({
    ...initform,
    user_id: idStudent ?? "",
    professional_id: professional_id,
  });
  useEffect(() => {
    setForm({
      ...initform,
      user_id: idStudent ?? "",
      professional_id: professional_id,
    });
  }, [isOpen]);

  const submit = async (e: any) => {
    e.preventDefault();
    const res = await appointment.createAppointment({
      ...form,
      date_time: form.date_time,
    });
    if (res) {
      toast.success("Agendamento criado com sucesso!");
      onSubmit();
    }
  };

  const start = async () => {
    const data = await students.listAll(professional_id);

    setAlunos(data?.students ?? []);
  };

  useEffect(() => {
    start();
  }, []);

  useEffect(() => {
    setForm({ ...form, user_id: idStudent });
  }, [idStudent]);

  useEffect(() => {
    setForm({
      ...form,
      date_time: selectedDate,
    });
  }, [selectedDate]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onBack()}
      style={{
        content: Styles.styleModal,
      }}
      contentLabel="Example Modal"
    >
      <form
        onSubmit={submit}
        className="p-4 max-h-[80vh] md:max-h-[80vh] lg:max-h-[98vh] "
      >
        <div className="flex w-full justify-center ">
          <div className="">
            <HeaderTitle title="Nova Aula" />

            <hr className="mt-4" />
            <div className="w-full h-full grid gap-1">
              <div className="mt-4">
                <div className="w-full grid gap-1">
                  <label
                    htmlFor="user_id"
                    className="text-md pl-1"
                    style={{ color: Colors.background }}
                  >
                    Aluno
                  </label>
                  <select
                    id="user_id"
                    name="user_id"
                    autoComplete="user_id"
                    required
                    value={form.user_id}
                    onChange={({ target }) =>
                      setForm({ ...form, [target.name]: target.value })
                    }
                    className="appearance-none border-y-[1px] border-x-[1px] border-hrc  rounded-3xl relative block w-full px-3 py-3 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm"
                  >
                    <option selected disabled></option>
                    {alunos.map((e: any) => (
                      <option value={e.user.id}>{e.user.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="flex flex-row items-center -mt-4">
                <div className="w-full grid gap-1">
                  <label
                    htmlFor="type"
                    className="text-md pl-1"
                    style={{ color: Colors.background }}
                  >
                    Tipo de Aula
                  </label>
                  <select
                    id="type"
                    name="type"
                    onChange={({ target }) =>
                      setForm({
                        ...form,
                        [target.name]: target.value,
                        duration_minutes:
                          Texts.atividadesComPersonalTrainer.filter(
                            (e) => e.enum === target?.value
                          )[0].tempo ?? 0,
                      })
                    }
                    autoComplete="type"
                    required
                    className="appearance-none border-y-[1px] border-x-[1px] border-hrc rounded-3xl relative block w-full px-3 py-3 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm"
                  >
                    <option selected disabled></option>
                    {Texts.atividadesComPersonalTrainer.map((e) => (
                      <option value={e.enum}>{e.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="w-full grid justify-center">
                <div className="w-full lg:max-w-[500px] -mt-4">
                  <div className="flex flex-row items-center pb-2 gap-6">
                    <InputDefault
                      label="Data"
                      id="date_time"
                      name="date_time"
                      type="datetime-local"
                      required={true}
                      value={
                        selectedDate
                          ? utils
                              .toLocalISOString(new Date(selectedDate))

                              .slice(0, 16)
                          : ""
                      }
                      placeholder="Endereço de e-mail"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const newDate = e.target.value;
                        setSelectedDate(newDate);
                      }}
                    />

                    <InputDefault
                      label="Duração (min)"
                      id="duration_minutes"
                      name="duration_minutes"
                      type="number"
                      value={form.duration_minutes}
                      required={true}
                      placeholder="Endereço de e-mail"
                      onChange={({ target }: any) => {
                        setForm({ ...form, [target.name]: target.value });
                      }}
                    />
                  </div>
                  <div
                    className="h-[350px] rounded-2xl overflow-hidden shadow-lg p-4 mt-2"
                    style={{
                      background: `linear-gradient(to left,${Colors.background},${Colors.background} ,${Colors.background2} )`,
                      color: "white",
                    }}
                  >
                    <Calendar
                      onChange={(e: any) => {
                        setSelectedDate(new Date(e).toISOString());
                      }}
                      value={new Date(selectedDate).toString()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <BottomModal type="submit" onBack={onBack} id={null} />
      </form>
    </Modal>
  );
}

export default NewApppointment;
