import React, { useState } from "react";
import {
  FiCalendar,
  FiFile,
  FiHome,
  FiLink,
  FiMenu,
  FiPower,
  FiSettings,
  FiUser,
} from "react-icons/fi";
import { FaAdn, FaAlipay, FaDumbbell, FaFax, FaGg } from "react-icons/fa";
import { useAuth } from "../context/AuthContext";
import helper from "../pages/helpers/helper";
import Colors from "../constants/Colors";
import img from "../assets/ic1.png";
import utils from "../helpers/utils";
import { MdCloudQueue, MdGamepad, MdGrade } from "react-icons/md";

const TopMenu = ({ toggleMenu, isOpen }: any) => {
  const { getUser }: any = useAuth();
  const user = getUser();

  return (
    <div
      className="py-4 "
      style={{
        background: Colors.background,
      }}
    >
      <div className="mx-auto flex justify-between items-center px-4">
        <div className="text-xl   font-bold italic">
          <span>{!helper.isMobile() ? user.name : null}</span>
        </div>

        <div className="flex items-center w-auto gap-16">
          <div>
            {isOpen ? (
              <FiMenu
                className="h-6 w-6  cursor-pointer"
                onClick={toggleMenu}
              />
            ) : (
              <FiMenu className="h-6 w-6 cursor-pointer" onClick={toggleMenu} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SideMenu = ({ isOpen }: any) => {
  const { logout, getUser }: any = useAuth();
  const MENUS = [
    {
      title: "Início",
      href: "/",
      icon: (
        <FiHome className={`h-6 w-6 ${isOpen ? "mr-4" : ""} cursor-pointer`} />
      ),
    },
    {
      title: "Agenda",
      href: "/appointments",
      icon: (
        <FiCalendar
          className={`h-6 w-6 ${isOpen ? "mr-4" : ""} cursor-pointer`}
        />
      ),
    },
    {
      title: "Alunos",
      href: "/students",
      icon: (
        <FiUser className={`h-6 w-6 ${isOpen ? "mr-4" : ""} cursor-pointer`} />
      ),
    },
    {
      title: "Exercícios",
      href: "/exercises",
      icon: (
        <FaDumbbell
          className={`h-6 w-6 ${isOpen ? "mr-4" : ""} cursor-pointer`}
        />
      ),
    },
    {
      title: "Relatórios",
      href: "/reports",
      icon: (
        <FiFile className={`h-6 w-6 ${isOpen ? "mr-4" : ""} cursor-pointer`} />
      ),
    },
    {
      title: "Ajustes",
      href: "/settings",
      icon: (
        <FiSettings
          className={`h-6 w-6 ${isOpen ? "mr-4" : ""} cursor-pointer`}
        />
      ),
    },
    {
      title: "Meu Link",
      bottom: true,
      icon: (
        <FiLink className={`h-6 w-6 ${isOpen ? "mr-4" : ""} cursor-pointer`} />
      ),
      onPress: () => window.open(`/profissional/${getUser().id}`, "_blank"),
    },

    {
      title: "Sair",
      bottom: true,
      icon: (
        <FiPower className={`h-6 w-6 ${isOpen ? "mr-4" : ""} cursor-pointer`} />
      ),
      onPress: logout,
    },
  ];

  return (
    <div
      className={`fixed text-white  h-screen  ${
        isOpen ? "w-60" : "w-25"
      } flex flex-col justify-between`}
      style={{
        background: `linear-gradient(to left, ${Colors.background}, ${Colors.background}, ${Colors.background}, ${Colors.background},${Colors.background},${Colors.background}, ${Colors.background},${Colors.background},${Colors.background}, ${Colors.background}, ${Colors.background}, ${Colors.background},  ${Colors.background2})`,
      }}
    >
      <div>
        <div className="text-xl font-bold py-2 px-6"></div>
        <ul className="mt-8">
          {MENUS.map((i) => (
            <li
              className={`mb-6 flex items-center  ${i.bottom ? "mt-14" : ""} ${
                isOpen ? "justify-start ml-4 pl-2" : "justify-center"
              }`}
              onClick={() =>
                i.onPress ? i.onPress() : (window.location.href = i.href || "#")
              }
            >
              {i.icon}
              {isOpen && (
                <a
                  href={i.href || "#"}
                  className="hover:text-gray-400 font-bold italic"
                >
                  {i.title}
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>
      {isOpen ? (
        <div className="flex justify-center mb-4">
          <img src={utils.getUriImage(img)} style={{ width: 50 }} />
        </div>
      ) : null}
    </div>
  );
};

const MenuLayout = ({ children, className }: any) => {
  const tagitem = "ISOPEN";
  const getItem = localStorage.getItem(tagitem);
  const [isOpen, setIsOpen] = useState(
    !utils.isMobile() ? getItem == "true" : false
  );

  const toggleMenu = () => {
    const res = !isOpen;
    setIsOpen(res);
    localStorage.setItem(tagitem, JSON.stringify(res));
  };

  return (
    <div className={"flex flex–row " + className ?? ""}>
      <div className={isOpen ? "mr-60" : "mr-10"}>
        <SideMenu isOpen={isOpen} />
      </div>

      <div className="w-full">
        <TopMenu toggleMenu={toggleMenu} isOpen={isOpen} />

        <div
          className={`w-full overflow-x-hidden mx-auto  justify-center mb-4 p-8`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default MenuLayout;
