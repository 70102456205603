import { FiEye, FiEyeOff, FiSave } from "react-icons/fi";
import Button from "../../components/Button";
import MenuLayout from "../../components/Menu";
import Colors from "../../constants/Colors";
import InputDefault from "../../components/InputDefault";
import utils from "../../helpers/utils";
import { useEffect, useState } from "react";
import Strings from "../../constants/Strings";
import { useAuth } from "../../context/AuthContext";
import ImageInput from "../../components/ImageInput";
import HeaderTitle from "../../components/HeaderTitle";
import linksthree from "../../services/linksthree";
import LinkButtonItem from "../../components/LinkButtonItem";
import { toast } from "react-toastify";
import Load from "../../components/Load";

function Perfil() {
  const { getUser }: any = useAuth();
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(true);

  const [linkOpen, setLinkOpen] = useState(false);

  const start = async () => {
    setLoad(true);
    setData(await linksthree.getButtons(getUser()?.id));
    setLoad(false);
  };

  const submit = async (body: any) => {
    setLoading(true);
    const response = await linksthree.putButton(getUser()?.id, body);
    if (response) {
      toast.success("Alteração salva com sucesso!");
    }
    setLoading(false);
  };

  const submitSave = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const response = await linksthree.putSave(getUser()?.id, data);
    if (response) {
      toast.success("Alteração salva com sucesso!");
    }
    setLoading(false);
  };

  const addButton = () => {
    setData({
      ...data,
      buttons: [
        ...(data.buttons ?? []),
        {
          id: utils.generateId(),
          url: "",
          label: "Novo Link",
          image: "",
          open: true,
        },
      ],
    });
  };

  const changeButton = (form: any) => {
    const btsn = data.button?.map((e: any) => {
      if (e.id === form.id) {
        return form;
      }
      return e;
    });
    const body = {
      ...data,
      buttons: btsn,
    };
    setData(body);
    submit(btsn);
  };

  const removeButton = (id: string) => {
    const btsn = data.buttons.filter((e: any) => {
      return e.id !== id;
    });

    setData({
      ...data,
      buttons: btsn,
    });
    submit(btsn);
  };

  useEffect(() => {
    start();
  }, []);

  return (
    <MenuLayout>
      <div className="flex w-full justify-center">
        <div className="container">
          <HeaderTitle title="Perfil" />
          {load ? (
            <div className="pt-14 pb-10">
              <center>
                <Load />
              </center>
            </div>
          ) : null}

          {data ? (
            <div className="w-full grid lg:flex lg:justify-between items-start gap-4 lg:gap-[6vw] pr-6">
              <form
                className="w-full flex justify-center flex-col item-center"
                onSubmit={submitSave}
              >
                <HeaderTitle title="Dados Pessoais" className="mt-4 text-sm" />
                <ImageInput
                  id="image"
                  classNameImage="h-[150px]"
                  name="image"
                  objectFit="contain"
                  background="transparent"
                  colorLabel={Colors.white}
                  onError={Strings.default_photo}
                  value={utils.getUriImage(
                    data?.photo !== "" ? data?.photo : Strings.default_photo
                  )}
                  onChange={(value: string) => {
                    setData({ ...data, photo: value });
                  }}
                />
                <div className="grid">
                  <InputDefault
                    label="Email"
                    value={getUser()?.email}
                    disabled={true}
                    // onChange={(e: any) => setData({ ...data, email: e.target.value })}
                  />
                  <InputDefault
                    label="Nome"
                    required={true}
                    className="lg:mt-2"
                    value={data?.name}
                    onChange={(e: any) =>
                      setData({ ...data, name: e.target.value })
                    }
                  />
                  <InputDefault
                    label="Descrição"
                    value={data?.description}
                    className="lg:mt-2"
                    onChange={(e: any) =>
                      setData({ ...data, description: e.target.value })
                    }
                  />
                </div>
                <div
                  className="flex items-center mt-8 justify-between"
                  onClick={() => setLinkOpen(!linkOpen)}
                >
                  <HeaderTitle
                    title="Meu Link - Cores"
                    className="text-base cursor-pointer underline italic"
                  />
                  {linkOpen ? (
                    <FiEyeOff
                      size={16}
                      color={Colors.background2}
                      className="cursor-pointer"
                    />
                  ) : (
                    <FiEye
                      size={16}
                      color={Colors.background2}
                      className="cursor-pointer"
                    />
                  )}
                </div>
                {linkOpen ? (
                  <div>
                    <InputDefault
                      label="Botões"
                      value={data?.color}
                      classNameInput="py-4"
                      type="color"
                      onChange={(e: any) =>
                        setData({ ...data, color: e.target.value })
                      }
                    />
                    <InputDefault
                      label="Texto dos Botões"
                      value={data?.color_secondary}
                      classNameInput="py-4"
                      type="color"
                      onChange={(e: any) =>
                        setData({ ...data, color_secondary: e.target.value })
                      }
                    />
                    <InputDefault
                      label="Cor de Fundo"
                      value={data?.background}
                      classNameInput="py-4"
                      className="border-none"
                      type="color"
                      onChange={(e: any) =>
                        setData({ ...data, background: e.target.value })
                      }
                    />
                  </div>
                ) : null}
                <Button
                  icon={<FiSave size={20} />}
                  type="submit"
                  loading={loading}
                  title="Salvar"
                  className="py-[13px] mt-8"
                />
              </form>
              <div className="w-full">
                <HeaderTitle title="Botões de Ação" className="mt-6 text-sm" />
                <div className="mt-4 gap-2 grid">
                  {data.buttons?.map((e: any) => (
                    <LinkButtonItem
                      data={e}
                      onSubmit={(f: any) => changeButton(f)}
                      onRemove={(id: string) => removeButton(id)}
                    />
                  ))}
                </div>
                <Button
                  className="py-[13px] mt-4 border-white"
                  onClick={() => addButton()}
                  loading={loading}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </MenuLayout>
  );
}

export default Perfil;
