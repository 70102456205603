import { useEffect, useState } from "react";
import MenuLayout from "../../components/Menu";
import Colors from "../../constants/Colors";
import utils from "../../helpers/utils";
import Button from "../../components/Button";
import Load from "../../components/Load";
import exerciciesModel from "../../services/exercicies";
import { useAuth } from "../../context/AuthContext";
import ExercicieItem from "../../components/ExercicieItem";
import ExercicieModal from "../../components/ExercicieModal";
import HeaderTitle from "../../components/HeaderTitle";

function Exercises() {
  const [busca, setBusca] = useState("");
  const [load, setLoad] = useState(true);
  const [exercicies, setExercicies] = useState([]);
  const { getUser }: any = useAuth();
  const [exercicieModal, setExercicieModal] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);

  const getall = async () => {
    setExercicies(
      await exerciciesModel.list_all(getUser()?.id, page, pageSize, busca)
    );
  };

  const start = async () => {
    setLoad(true);
    await getall();
    setLoad(false);
  };

  useEffect(() => {
    start();
  }, [page, busca]);

  useEffect(() => {
    getall();
  }, [exercicieModal]);

  return (
    <MenuLayout>
      <div className="flex w-full justify-center">
        <div className="container justify-start">
          <HeaderTitle title="Exercícios" />

          <div className="mt-6">
            <form>
              <div className="">
                <label
                  htmlFor="date_time"
                  className="text-lg font-bold mb-2 pl-1"
                  style={{ color: Colors.background }}
                >
                  Buscar:
                </label>
                <div className="grid sm:grid md:flex lg:flex gap-4">
                  <input
                    onChange={({ target }) =>
                      setBusca(utils.removerAcentos(target.value))
                    }
                    required
                    placeholder=" Nome do exercício"
                    className="appearance-none border-y-[1px] border-x-[1px] border-hrc rounded-3xl relative block w-full px-3 py-3 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm mt-2"
                  />

                  <Button
                    title="Novo Exercício"
                    className="sm:w-full md:w-[210px] lg:max-w-[210px]"
                    onClick={() =>
                      setExercicieModal({
                        name: "Novo exercício",
                        active: true,
                        image: "",
                        video: "",
                      })
                    }
                  />
                </div>
              </div>
              <div className="mt-4">
                <h3
                  className="text-lg font-bold mb-2 pl-1"
                  style={{ color: Colors.background }}
                >
                  Todos Exercícios:
                </h3>
                {load ? (
                  <div className="w-[full] flex justify-center pt-6 pb-6">
                    <Load />
                  </div>
                ) : (
                  <div className="mt-4 justify-between gap-4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {exercicies.map((e: any) => (
                      <ExercicieItem
                        data={e}
                        onClick={(e: any) => setExercicieModal(e)}
                      />
                    ))}
                  </div>
                )}
                <center>
                  {exercicies.filter((e: any) =>
                    utils
                      .removerAcentos(e.name.toLowerCase())
                      .includes(utils.removerAcentos(busca.toLowerCase()))
                  ).length <= 0 && !load ? (
                    <span
                      className="mt-4"
                      style={{ color: Colors.background2 }}
                    >
                      Nenhum execício cadastrado.
                    </span>
                  ) : null}
                </center>
              </div>
            </form>
          </div>

          <div
            style={{
              flex: 1,
              marginTop: 25,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              gap: 20,
            }}
          >
            {page !== 1 ? (
              <a
                className="select-none"
                onClick={() => setPage(page > 1 ? page - 1 : 1)}
                style={{
                  color: Colors.background2,
                  fontWeight: "500",
                  fontStyle: "italic",
                  fontSize: 17,
                  cursor: "pointer",
                }}
              >
                {"< "}Anterior
              </a>
            ) : (
              <a></a>
            )}
            {exercicies.length >= pageSize ? (
              <a
                onClick={() => setPage(page + 1)}
                className="select-none"
                style={{
                  color: Colors.background2,
                  fontWeight: "500",
                  fontStyle: "italic",
                  fontSize: 17,
                  cursor: "pointer",
                }}
              >
                Próximo{" >"}
              </a>
            ) : null}
          </div>
        </div>
      </div>
      <ExercicieModal
        isOpen={exercicieModal !== null}
        onBack={() => setExercicieModal(null)}
        data={exercicieModal}
      />
    </MenuLayout>
  );
}

export default Exercises;
