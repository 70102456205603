import uniqid from "uniqid";
import api from "../services/api";

function getFormattedDate(date = new Date()) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Mês começa de 0 (janeiro) a 11 (dezembro)
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

function formatDateAndTime(date: Date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const time = formatTime(date);
  return `${day}/${month}/${year} - ${time}`;
}

function formatTime(date: Date) {
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
}

function formatDate(date: Date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function addArray(array: any, defaultValue: any) {
  const lengthDiff = 6 - array.length;
  if (lengthDiff > 0) {
    const filledArray = Array.from({ length: lengthDiff }, () => defaultValue);
    return filledArray.concat(array);
  }
  return array;
}

function lastSixMonths() {
  const monthsAbbr = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const currentMonth = new Date().getMonth();

  // Cria um array com os índices dos últimos seis meses, incluindo o mês atual
  const lastSixMonthsIndices = Array.from(
    { length: 6 },
    (_, i) => (currentMonth - i + 12) % 12
  );

  // Mapeia os índices dos meses para suas abreviações correspondentes
  const lastSixMonthsAbbr = lastSixMonthsIndices.map(
    (index) => monthsAbbr[index]
  );

  return lastSixMonthsAbbr;
}

function isMobile() {
  const mobileWidthThreshold = 768;

  return window.innerWidth < mobileWidthThreshold;
}

function getUriImage(myValue: string, user_id = "") {
  if (myValue === "" || !myValue) {
    return "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png";
  }

  if (myValue.indexOf("static/media") != -1) {
    return myValue;
  }

  if (user_id !== "") {
    const final =
      myValue.indexOf("http") !== -1
        ? myValue
        : `${api.getUri()}/download/${user_id}/${myValue}`;

    return final;
  }
  const final =
    myValue.indexOf("http") !== -1
      ? myValue
      : `${api.getUri()}/download/${myValue}`;

  return final;
}

function calcularIdade(dataNascimento: Date): number {
  const hoje: Date = new Date();
  const anoAtual: number = hoje.getFullYear();
  const anoNascimento: number = dataNascimento.getFullYear();
  const idade: number = anoAtual - anoNascimento;

  const mesAtual: number = hoje.getMonth() + 1;
  const diaAtual: number = hoje.getDate();
  const mesNascimento: number = dataNascimento.getMonth() + 1;
  const diaNascimento: number = dataNascimento.getDate();

  if (
    mesAtual < mesNascimento ||
    (mesAtual === mesNascimento && diaAtual < diaNascimento)
  ) {
    return idade - 1;
  }

  return idade;
}

function formatarTelefone(numero: string) {
  const apenasDigitos = numero.replace(/\D/g, "");

  let prefixo = "";
  if (apenasDigitos.length > 11) {
    prefixo = `+${apenasDigitos.substring(0, apenasDigitos.length - 11)}`;
  }

  const regexPadrao = /^(?:\d{2})?(\d{2})(\d{4,5})(\d{4})$/;
  const partes = apenasDigitos.match(regexPadrao);

  if (partes) {
    return `${prefixo} (${partes[1]}) ${partes[2]}-${partes[3]}`;
  } else {
    return numero;
  }
}

function removerAcentos(s: string): string {
  return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function generateId(ids = "") {
  return uniqid(ids) + uniqid();
}

function formatPhoneNumber(phoneNumber: string) {
  const cleaned = phoneNumber.replace(/\D/g, "");

  const isValidNumber = /^[0-9]{10}$/g.test(cleaned);

  if (!isValidNumber) {
    return null;
  }

  const formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(
    2,
    6
  )}-${cleaned.slice(6)}`;

  return formatted;
}

function toLocalISOString(date: any) {
  const tzOffset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
  const localISOTime = new Date(date - tzOffset).toISOString().slice(0, 16);
  return localISOTime;
}
function truncateString(str: string, maxLength: number) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength - 3) + "...";
  }
  return str;
}

export default {
  getFormattedDate,
  getUriImage,
  formatDateAndTime,
  calcularIdade,
  truncateString,
  isMobile,
  removerAcentos,
  formatTime,
  addArray,
  generateId,
  formatPhoneNumber,
  toLocalISOString,
  formatarTelefone,
  formatDate,
  lastSixMonths,
};
