const avalicao = {
  biopedancia: {
    percentual_gordura: "",
    percentual_musculo: "",
  },
  idade_metabolica: "",
  taxa_metabolica: "",
  indice_de_gordura_visceral: "",
  gordura_visceral: "",
  dobra_cutanea: {
    tricipal: "",
    subescapular: "",
    biceps: "",
    suprailiaca: "",
    axilar_media: "",
    abdominal: "",
    coxa: "",
    panturrilha: "",
  },
  braco_esquerdo: "",
  braco_esquerdo_contraido: "",
  torax: "",
  quadril: "",
  coxa_esquerda: "",
  perna_esquerda: "",

  braco_direito: "",
  braco_direito_contraido: "",
  cintura: "",
  abdomen: "",
  coxa_direita: "",
  perna_direita: "",

  peso: "",
  altura: "",
  idade: "",
  user_id: "",
};

export default {
  avalicao,
};
