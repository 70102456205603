import { useEffect, useState } from "react";
import MenuLayout from "../../components/Menu";
import Calendar from "react-calendar";
import Colors from "../../constants/Colors";
import AppointmentItem from "../../components/AppointmentItem";
import appointment from "../../services/appointment";
import { useAuth } from "../../context/AuthContext";
import utils from "../../helpers/utils";
import Load from "../../components/Load";
import Texts from "../../constants/Texts";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import NewApppointment from "./newAppointment";
import StudentModal from "../../components/StudentModal";
import { usePrescription } from "../../context/PrescriptionContext";
import HeaderTitle from "../../components/HeaderTitle";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

function Appointments() {
  const [selectedDate, setSelectedDate] = useState<string>(
    new Date().toISOString()
  );
  const { getUser }: any = useAuth();
  const { userModal, setUserModal }: any = usePrescription();

  const navigate = useNavigate();
  const [appointments, setAppointments] = useState<any>([]);
  const [load, setLoad] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    init();
  }, [selectedDate]);

  async function init() {
    setLoad(true);
    const data = await appointment.getDayAppointment(
      getUser()?.id,
      new Date(selectedDate)
    );
    setAppointments(data);
    setLoad(false);
  }

  return (
    <MenuLayout className="overflow-hidden">
      <div className="flex w-full justify-center overflow-y-hidden">
        <div className="container justify-start">
          <HeaderTitle title="Agenda" />

          <div className="mt-4  w-full h-full justify-between grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
            <div className="mb-8 sm:mb-8 md:mb-8 lg:mb-0">
              <div className="w-full grid justify-start">
                <h4
                  className="mb-4 italic"
                  style={{ color: Colors.background }}
                >
                  Calendário
                </h4>
                <div className="w-full lg:max-w-[500px] overflow-x-auto">
                  <div
                    className="h-[350px] rounded-2xl overflow-hidden shadow-lg p-4"
                    style={{
                      background: `linear-gradient(to left,${Colors.background},${Colors.background} ,${Colors.background2} )`,
                      color: "white",
                    }}
                  >
                    <Calendar
                      onChange={(e: any) => setSelectedDate(e)}
                      value={new Date(selectedDate)}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    onClick={() => setOpenModal(true)}
                    className="bg-gradient-to-r from-blue-500 to-green-500 w-full flex justify-center py-4 px-4 rounded-3xl font-bold italic"
                    style={{
                      color: Colors.white,
                      background: `linear-gradient(to left,${Colors.background},${Colors.background} ,${Colors.background2} )`,
                    }}
                    disabled={load}
                  >
                    <div className="flex items-center gap-2">
                      <FiPlus size={20} /> Nova Aula
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="w-full">
              <h4 className="mb-4 italic" style={{ color: Colors.background }}>
                Agenda do dia{" "}
                <span
                  className="font-bold"
                  style={{ color: Colors.background }}
                >
                  {utils.formatDate(new Date(selectedDate))}
                </span>
              </h4>
              {!load ? (
                <div className="w-[full] h-[60vh] overflow-y-auto pr-5 pl-0 sm:pl-0 md:pl-0 lg:pl-4">
                  {appointments.map((e: any) => (
                    <div className="mb-4">
                      <AppointmentItem
                        data={e}
                        onClick={(a: any) => {
                          setUserModal(a.user);
                        }}
                      />
                    </div>
                  ))}
                  {appointments.length === 0 ? (
                    <div className="flex justify-center items-center h-1/2">
                      <span
                        style={{ color: Colors.background }}
                        className="italic  font-bold"
                      >
                        Nenhuma aula para este dia.
                      </span>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="w-[full] h-[60vh] flex justify-center pt-14">
                  <Load />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <NewApppointment
        onSubmit={() => {
          setOpenModal(false);
          init();
        }}
        isOpen={openModal}
        onBack={() => setOpenModal(false)}
      />
    </MenuLayout>
  );
}

export default Appointments;
