import { FaDumbbell } from "react-icons/fa";
import Colors from "../constants/Colors";
import Strings from "../constants/Strings";
import utils from "../helpers/utils";
import { FiArrowRight, FiClipboard, FiEye } from "react-icons/fi";

function AvaliationItem({ data, onClick }: any) {
  return (
    <div
      onClick={() => (onClick ? onClick(data) : null)}
      className="rounded-2xl p-3 flex flex-row justify-between items-center gap-2 select-none cursor-pointer w-full shadow-lg"
      style={{
        background: `linear-gradient(to left, ${Colors.background},${Colors.background},${Colors.background},${Colors.background}, ${Colors.background2})`,
      }}
    >
      <div className="flex flex-col justify-between gap-1">
        <span className="font-bold text-xs flex items-center gap-2">
          <FiClipboard size={20} />
          {utils.formatDateAndTime(new Date(data?.registe))}
        </span>
      </div>
      <div className="flex flex-col">
        <FiArrowRight size={12} />
      </div>
    </div>
  );
}

export default AvaliationItem;
