import api from "./api";

async function list_all(
  professional_id: string,
  page = 1,
  per_page = 10,
  busca = ""
) {
  try {
    const { data } = await api.get(
      `/exercicies/${professional_id}?page=${page}&per_page=${per_page}&search=${busca}`
    );
    return data;
  } catch (e) {
    return [];
  }
}

async function update(exercicie_id: string, body: any) {
  try {
    const { data } = await api.put(`/exercicies/${exercicie_id}`, body);
    return true;
  } catch (e) {
    return false;
  }
}

async function create(body: any) {
  try {
    const { data } = await api.post(`/exercicies`, body);
    return true;
  } catch (e) {
    return false;
  }
}

async function deleteItem(id: string) {
  try {
    const { data } = await api.delete(`/exercicies/${id}`);
    return true;
  } catch (e) {
    return false;
  }
}

export default { list_all, update, create, deleteItem };
