import React, { useEffect, useState } from "react";
import InputDefault from "../../components/InputDefault";
import HeaderTitle from "../../components/HeaderTitle";
import Objects from "../../constants/Objects";
import BottomModal from "../../components/BottomModal";
import Modal from "react-modal";
import Styles from "../../constants/Styles";
import avaliations from "../../services/avaliations";
import { toast } from "react-toastify";

const AvaliacaoForm = ({ isOpen, onBack, data }: any) => {
  const [form, setForm] = useState<any>({
    ...Objects.avalicao,
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name.indexOf(".") !== -1) {
      const [mainKey, subKey] = name.split(".");
      setForm({
        ...form,
        [mainKey]: {
          ...form[mainKey],
          [subKey]: value,
        },
      });
    } else {
      setForm({
        ...form,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (data.id) {
      const resp = await avaliations.update(form);
      if (resp) {
        toast.success("Avaliação atualizaca com sucesso!");
        onBack();
      } else {
        toast.error(
          "Erro ao atualizar a avaliação, por favor, tente novamente."
        );
      }
    } else {
      const resp = await avaliations.create(form);

      if (resp) {
        toast.success("Avaliação criada com sucesso!");
        onBack();
      } else {
        toast.error("Erro ao cadastrar avaliação, por favor, tente novamente.");
      }
    }
  };

  useEffect(() => {
    setForm(data);
  }, [isOpen]);

  if (!form) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onBack()}
      style={{
        content: Styles.styleModal,
      }}
      contentLabel="Example Modal"
    >
      <div className="max-h-[100vh]">
        <HeaderTitle title="Avaliação Física" className="pl-4 pt-6" />
        <hr className="mt-4" />

        <form
          onSubmit={handleSubmit}
          className="grid w-[98vw] sm:w-[98vw] md:w-[100vw] lg:w-[50vw] h-[auto] sm:h-[auto] md:h-[auto] lg:h-[auto]  p-4 pt-0"
        >
          <div className="flex w-full">
            <div className="container">
              <div className="w-full">
                <div className="mb-8 sm:mb-8 md:mb-8 lg:mb-0 w-full mt-4 overflow-y-auto">
                  <div className="justify-between gap-4 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
                    <InputDefault
                      label="Peso"
                      type="text"
                      name="peso"
                      value={form?.peso}
                      onChange={handleChange}
                    />
                    <InputDefault
                      label="Altura"
                      type="text"
                      name="altura"
                      value={form.altura}
                      onChange={handleChange}
                    />
                    <InputDefault
                      label="Idade"
                      type="text"
                      name="idade"
                      value={form.idade}
                      onChange={handleChange}
                    />

                    <InputDefault
                      label="Braço Esquerdo"
                      type="text"
                      name="braco_esquerdo"
                      value={form.braco_esquerdo}
                      onChange={handleChange}
                    />
                    <InputDefault
                      label="Braço Esquerdo Contraído"
                      type="text"
                      name="braco_esquerdo_contraido"
                      value={form.braco_esquerdo_contraido}
                      onChange={handleChange}
                    />

                    <InputDefault
                      label="Tórax"
                      type="text"
                      name="torax"
                      value={form.torax}
                      onChange={handleChange}
                    />
                    <InputDefault
                      label="Quadril"
                      type="text"
                      name="quadril"
                      value={form.quadril}
                      onChange={handleChange}
                    />

                    <InputDefault
                      label="Coxa Esquerda"
                      type="text"
                      name="coxa_esquerda"
                      value={form.coxa_esquerda}
                      onChange={handleChange}
                    />
                    <InputDefault
                      label="Perna Esquerda"
                      type="text"
                      name="perna_esquerda"
                      value={form.perna_esquerda}
                      onChange={handleChange}
                    />
                  </div>

                  <HeaderTitle
                    title="Dobras Cutâneas"
                    className="text-md font-light mt-12 mb-4"
                  />
                  <hr className="mt-4" />
                  <div className="justify-between gap-4 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
                    <InputDefault
                      label="Tricipal"
                      type="text"
                      name="dobra_cutanea.tricipal"
                      value={form.dobra_cutanea.tricipal}
                      onChange={handleChange}
                    />
                    <InputDefault
                      label="Subescapular"
                      type="text"
                      name="dobra_cutanea.subescapular"
                      value={form.dobra_cutanea.subescapular}
                      onChange={handleChange}
                    />
                    <InputDefault
                      label="Bíceps"
                      type="text"
                      name="dobra_cutanea.biceps"
                      value={form.dobra_cutanea.biceps}
                      onChange={handleChange}
                    />

                    <InputDefault
                      label="Supraíliaca"
                      type="text"
                      name="dobra_cutanea.suprailiaca"
                      value={form.dobra_cutanea.suprailiaca}
                      onChange={handleChange}
                    />
                    <InputDefault
                      label="Axilar Média"
                      type="text"
                      name="dobra_cutanea.axilar_media"
                      value={form.dobra_cutanea.axilar_media}
                      onChange={handleChange}
                    />
                    <InputDefault
                      label="Abdominal"
                      type="text"
                      name="dobra_cutanea.abdominal"
                      value={form.dobra_cutanea.abdominal}
                      onChange={handleChange}
                    />
                    <InputDefault
                      label="Coxa"
                      type="text"
                      name="dobra_cutanea.coxa"
                      value={form.dobra_cutanea.coxa}
                      onChange={handleChange}
                    />

                    <InputDefault
                      label="Panturrilha"
                      type="text"
                      name="dobra_cutanea.panturrilha"
                      value={form.dobra_cutanea.panturrilha}
                      onChange={handleChange}
                    />
                  </div>

                  <HeaderTitle
                    title="Bioimpedância"
                    className="text-md font-light mt-12 mb-4"
                  />
                  <hr className="mt-4" />
                  <div className="justify-between gap-4 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
                    <InputDefault
                      label="Percentual de Gordura"
                      type="text"
                      name="biopedancia.percentual_gordura"
                      value={form.biopedancia.percentual_gordura}
                      onChange={handleChange}
                    />
                    <InputDefault
                      label="Percentual de Músculo"
                      type="text"
                      name="biopedancia.percentual_musculo"
                      value={form.biopedancia.percentual_musculo}
                      onChange={handleChange}
                    />

                    <InputDefault
                      label="Idade Metabólica"
                      type="text"
                      name="idade_metabolica"
                      value={form.idade_metabolica}
                      onChange={handleChange}
                    />
                    <InputDefault
                      label="Taxa Metabólica"
                      type="text"
                      name="taxa_metabolica"
                      value={form.taxa_metabolica}
                      onChange={handleChange}
                    />
                    <InputDefault
                      label="Índice de Gordura Visceral"
                      type="text"
                      name="indice_de_gordura_visceral"
                      value={form.indice_de_gordura_visceral}
                      onChange={handleChange}
                    />
                    <InputDefault
                      label="Gordura Visceral"
                      type="text"
                      name="gordura_visceral"
                      value={form.gordura_visceral}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="pb-2 pt-2 p-4">
          <BottomModal onBack={onBack} onSave={handleSubmit} />
        </div>
      </div>
    </Modal>
  );
};

export default AvaliacaoForm;
