import Colors from "./Colors";

export default {
  styleModal: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",

    marginRight: "-50%",
    background: Colors.white,
    transform: "translate(-50%, -50%)",
    padding: 0,
    zIndex: 99999999,
    borderRadius: 15,
  },
};
