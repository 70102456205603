import { FiDelete } from "react-icons/fi";
import Colors from "../constants/Colors";
import Strings from "../constants/Strings";
import utils from "../helpers/utils";

function ExercicieItem({ data, onClick }: any) {
  return (
    <div
      onClick={() => onClick(data)}
      className="rounded-2xl p-4 flex items-center gap-2 select-none cursor-pointer w-full shadow-lg"
      style={{
        background: `linear-gradient(to left, ${Colors.background},${Colors.background},${Colors.background},${Colors.background}, ${Colors.background2})`,
      }}
    >
      <img
        src={utils.getUriImage(
          data?.image ?? Strings.default_photo,
          "all_view"
        )}
        style={{
          width: 90,
          height: 75,
          objectFit: "cover",
          background: "white",
        }}
        className="rounded bg-white"
      />
      <div className="flex flex-row justify-between gap-3 w-full">
        <div className="w-full">
          <span className="font-bold text-lg overflow-hidden">
            {data?.name}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ExercicieItem;
