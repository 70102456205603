import api from "./api";

async function crescimentoUsers(professional_id: string) {
  try {
    const { data } = await api.get(`/reports/users/${professional_id}`);
    return data;
  } catch (e) {
    return [];
  }
}

async function workoutsGrow(professional_id: string) {
  try {
    const { data } = await api.get(`/reports/workouts/${professional_id}`);
    return data;
  } catch (e) {
    return [];
  }
}

async function getDashAulas(professional_id: string) {
  try {
    const { data } = await api.get(`/appointments/reports/${professional_id}`);
    return data;
  } catch (e) {
    return [];
  }
}

async function getReports() {
  try {
    const { data } = await api.get(`/user-log`);
    return data;
  } catch (e) {
    return [];
  }
}
async function genReport(form: any) {
  try {
    const { data } = await api.post(`/generate-report`, form);
    return data;
  } catch (e) {
    return [];
  }
}

export default {
  crescimentoUsers,
  workoutsGrow,
  getDashAulas,
  getReports,
  genReport,
};
