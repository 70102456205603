import Colors from "../constants/Colors";

function HeaderTitle({ title, className }: any) {
  return (
    <h4
      style={{ color: Colors.background }}
      className={"font-bold italic text-2xl " + className}
    >
      {title}
    </h4>
  );
}

export default HeaderTitle;
