import React, { useState, useContext, useEffect, createContext } from "react";
import WorkoutModal from "../components/WorkoutModal";
import StudentModal from "../components/StudentModal";

import FichaModel from "../components/FichaModal";
import PrescriptionExercicieModal from "../components/PrescriptionExercicieModal";
import utils from "../helpers/utils";
import { toast } from "react-toastify";
import workoutplans from "../services/workoutplans";
import AvaliacaoForm from "../pages/avaliacao";
import Objects from "../constants/Objects";

const PrescriptionContext: any = createContext<any>({});

const INIT_WORK = {
  plan_name: "",
  objective: "",
  start_date: new Date(),
  end_date: new Date(),
  id: null,
  created_at: new Date(),
  prescriptions: [],
};

const NEWEXERCICIE = {
  sets: "",
  reps: "",
  mid: utils.generateId(),
  id: null,
  interval: "",
  charge: "",
};

export const PrescriptionProvider = ({ children }: any) => {
  const [workout, setWorkout] = useState<any>(null);
  const [ficha, setFicha] = useState<any>(null);
  const [exercicie, setExercicie] = useState(null);
  const [avaliacao, setAvaliacao] = useState<any>(null);
  const [userModal, setUserModal] = useState<any>(null);

  const submitWorkout = async (form: any) => {
    form = {
      ...workout,
      ...form,
      prescriptions: workout?.prescriptions,
    };

    if (form?.id) {
      const rep = await workoutplans.update_plan(form.id, form);
      if (rep) {
        toast.success("Plano de Treino atualizado com sucesso!");
        reset();
      }
    } else {
      const rep = await workoutplans.create_plan({
        ...form,
        user_id: userModal?.id,
      });
      if (rep) {
        toast.success("Plano de Treino criado com sucesso!");
        reset();
      }
    }
  };

  const reset = () => {
    setWorkout(null);
    setFicha(null);
    setExercicie(null);
  };

  const newPrescription = () => {
    setWorkout(INIT_WORK);
  };

  const saveWorkout = (workouts: any) => {
    setWorkout({
      ...workout,
      ...workouts,
    });
  };

  function addFicha(ids = utils.generateId()) {
    const init = {
      duration: "",
      end_date: "",
      exercises: [],
      id: ids + utils.generateId(),
      intensity: "",
      name: "",
      start_date: "",
      workout_type: "",
    };
    let fins = workout?.prescriptions ?? ([] as any[]);

    fins.push(init);

    setWorkout({
      ...workout,
      prescriptions: fins,
    });
  }

  const setFichaId = (id: string) => {
    const fd = workout?.prescriptions?.filter((e: any) => e.id === id);
    if (fd && fd.length > 0) {
      setFicha(fd[0]);
    }
  };

  const removeFichaId = (ids: string, form: any) => {
    const fd = workout?.prescriptions?.filter((e: any) => e.id !== ids);
    setWorkout({
      ...workout,
      prescriptions: fd,
    });
  };

  const saveExercicie = (save: any) => {
    const m_exercises = ficha?.exercises?.filter((e: any) => {
      if (save.mid && e.mid === save.mid) {
        return true;
      }
      return false;
    });

    if (m_exercises.length > 0) {
      const finalFicha = {
        ...ficha,
        exercises: ficha?.exercises.map((e: any) => {
          if (save.mid && e.mid === save.mid) {
            return save;
          }
          return e;
        }),
      };
      setFicha(finalFicha);
      updateWorkoutByFicha(finalFicha);
    } else {
      const final = [
        ...ficha.exercises,
        { mid: utils.generateId(save.mid), ...save },
      ];
      const finalFicha = {
        ...ficha,
        exercises: final,
      };
      setFicha(finalFicha);
      updateWorkoutByFicha(finalFicha);
    }
    setExercicie(null);
  };

  const addExercicie = () => {
    const finalExercicies = [
      ...ficha.exercises,
      { ...NEWEXERCICIE, mid: utils.generateId() },
    ];

    const finalFicha = {
      ...ficha,
      exercises: finalExercicies,
    };

    setFicha(finalFicha);
    updateWorkoutByFicha(finalFicha);
  };

  const removeExercicie = (mid: string) => {
    const finalExercicies = ficha.exercises.filter((e: any) => e.mid != mid);
    const finalFicha = {
      ...ficha,
      exercises: finalExercicies,
    };
    setFicha(finalFicha);
    updateWorkoutByFicha(finalFicha);
  };

  const updateFicha = (newficha: any) => {
    updateWorkoutByFicha(newficha);
  };

  const updateWorkoutByFicha = (finalFicha: any) => {
    const prescriptionss = workout?.prescriptions.map((s: any) => {
      if (s.id === finalFicha.id) {
        return finalFicha;
      }
      return s;
    });

    setWorkout({
      ...workout,
      prescriptions: prescriptionss,
    });
  };

  const addAvaliation = (user_id: string, professional_id: string) => {
    setAvaliacao({ ...Objects.avalicao, professional_id, user_id });
  };

  const updateAvaliation = (avaliation: any, id = null) => {
    setAvaliacao({ ...Objects.avalicao, ...avaliation, id });
  };

  return (
    <PrescriptionContext.Provider
      value={{
        userModal,
        workout,
        exercicie,
        INIT_WORK,
        addExercicie,
        addAvaliation,
        removeExercicie,
        setUserModal,
        setExercicie,
        setWorkout,
        removeFichaId,
        setFichaId,
        updateFicha,
        saveExercicie,
        setFicha,
        submitWorkout,
        addFicha,
        updateAvaliation,
        saveWorkout,
        newPrescription,
      }}
    >
      {children}

      <StudentModal
        isOpen={userModal !== null && !workout && !avaliacao}
        data={userModal}
        onBack={() => setUserModal(null)}
        buttonOff={true}
      />

      <WorkoutModal
        data={workout}
        isOpen={workout !== null && !ficha}
        onBack={() => setWorkout(null)}
        user_id={userModal?.id ?? null}
      />

      <FichaModel
        isOpen={ficha !== null && !exercicie}
        data={ficha}
        onBack={() => setFicha(null)}
      />

      <PrescriptionExercicieModal
        isOpen={exercicie !== null}
        data={exercicie}
        onBack={() => setExercicie(null)}
      />
      <AvaliacaoForm
        isOpen={avaliacao !== null}
        data={avaliacao}
        onBack={() => setAvaliacao(null)}
      />
    </PrescriptionContext.Provider>
  );
};

// Crie um hook personalizado para acessar o contexto de autenticação
export const usePrescription = () => useContext(PrescriptionContext);
