import { FaDumbbell } from "react-icons/fa";
import { MdDelete, MdFitnessCenter } from "react-icons/md";

import Colors from "../constants/Colors";
import Strings from "../constants/Strings";
import utils from "../helpers/utils";

function FichaItem({
  data,
  onClick,
  nameLabel,
  onDelete,
  label,
  iconVariant = false,
}: any) {
  return (
    <div
      className="rounded-2xl p-2 py-3 flex items-center justify-between gap-6 select-none cursor-pointer w-full shadow-lg"
      style={{
        background: `linear-gradient(to left, ${Colors.background},${Colors.background},${Colors.background},${Colors.background}, ${Colors.background2})`,
      }}
    >
      <div
        className="flex  gap-4 items-center w-full"
        onClick={() => onClick(data)}
      >
        {iconVariant ? (
          <MdFitnessCenter size={25} className="ml-2" />
        ) : (
          <FaDumbbell size={25} className="ml-2" />
        )}
        <div className="flex flex-col justify-between">
          <span className="font-bold text-xs line">
            {nameLabel ?? data?.name}
          </span>
          <span className="italic font-normal text-xs mt-1">
            <span className="font-light">
              {label ?? `${data?.exercises?.length ?? 0} exercícios`}
            </span>
          </span>
        </div>
      </div>
      <div className="mr-4" onClick={() => onDelete(data)}>
        <MdDelete size={20} style={{ padding: 2 }} />
      </div>
    </div>
  );
}

export default FichaItem;
