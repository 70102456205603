import { useEffect, useState } from "react";
import MenuLayout from "../../components/Menu";
import Colors from "../../constants/Colors";
import students from "../../services/students";
import { useAuth } from "../../context/AuthContext";
import StudentItem from "../../components/StudentItem";
import Load from "../../components/Load";

import Button from "../../components/Button";
import utils from "../../helpers/utils";
import { usePrescription } from "../../context/PrescriptionContext";
import HeaderTitle from "../../components/HeaderTitle";
import NewStudentModal from "./NewStudentModal";

function Students() {
  const { getUser }: any = useAuth();
  const [alunos, setAlunos] = useState([]);
  const [load, setLoad] = useState(true);
  const [busca, setBusca] = useState("");
  const [novoAluno, setNovoAluno] = useState(false);

  const { userModal, setUserModal }: any = usePrescription();

  const start = async () => {
    setLoad(true);
    const data = await students.listAll(getUser()?.id);
    if (data?.students) setAlunos(data?.students);
    setLoad(false);
  };

  useEffect(() => {
    if (!novoAluno) start();
  }, [novoAluno]);

  useEffect(() => {
    if (!userModal) {
      setUserModal(null);
    }
  }, [userModal]);

  return (
    <MenuLayout>
      <div className="flex w-full justify-center">
        <div className="container justify-start">
          <HeaderTitle title="Alunos" />

          <div className="mt-6">
            <form>
              <div className="">
                <label
                  htmlFor="date_time"
                  className="text-lg font-bold mb-2 pl-1"
                  style={{ color: Colors.background }}
                >
                  Buscar:
                </label>
                <div className="grid sm:grid md:flex lg:flex gap-4">
                  <input
                    onChange={({ target }) =>
                      setBusca(utils.removerAcentos(target.value))
                    }
                    required
                    placeholder=" Nome do aluno"
                    className="appearance-none border-y-[1px] border-x-[1px] border-hrc rounded-3xl relative block w-full px-3 py-3 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm mt-2"
                  />

                  <Button
                    title="Novo Aluno"
                    className="sm:w-full md:w-[200px] lg:max-w-[200px]"
                    onClick={() => setNovoAluno(!novoAluno)}
                  />
                </div>
              </div>
              <div className="mt-4">
                <h3
                  className="text-lg font-bold mb-2 pl-1"
                  style={{ color: Colors.background }}
                >
                  Todos Alunos:
                </h3>
                {load ? (
                  <div className="w-[full] flex justify-center pt-6 pb-6">
                    <Load />
                  </div>
                ) : null}
                <div className="mt-4 justify-between gap-4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  {alunos
                    .filter((e: any) =>
                      utils
                        .removerAcentos(e.user.name.toLowerCase())
                        .includes(utils.removerAcentos(busca.toLowerCase()))
                    )
                    .map((e: any) => (
                      <StudentItem
                        onClick={(res: any) => setUserModal(e.user)}
                        key={e.id}
                        data={e}
                      />
                    ))}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <NewStudentModal
        isOpen={novoAluno}
        onBack={() => setNovoAluno(!novoAluno)}
      />
    </MenuLayout>
  );
}

export default Students;
