import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import LoginPage from "./pages/login";
import { useAuth } from "./context/AuthContext";
import Home from "./pages/home";
import Appointments from "./pages/appointment";
import CreateAppointment from "./components/CreateAppointment";
import NewAppointment from "./pages/appointment/newAppointment";
import Students from "./pages/students";
import Exercises from "./pages/exercises";
import Reports from "./pages/reports";
import LinkThree from "./pages/linkthree";
import Perfil from "./pages/perfil";

function Routes() {
  const { isLogged }: any = useAuth();
  const LINKTHREE = {
    path: "/profissional/:id",
    element: <LinkThree />,
  };

  const authRoute = createBrowserRouter([
    {
      path: "/",
      element: <LoginPage />,
    },
    LINKTHREE,
    {
      path: "*",
      element: <LoginPage />,
    },
  ]);

  const userStack = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },

    {
      path: "/appointments",
      element: <Appointments />,
    },
    {
      path: "/students",
      element: <Students />,
    },
    {
      path: "/exercises",
      element: <Exercises />,
    },
    {
      path: "/reports",
      element: <Reports />,
    },
    {
      path: "/settings",
      element: <Perfil />,
    },
    LINKTHREE,
  ]);

  return (
    <div>
      {isLogged ? (
        <RouterProvider router={userStack} />
      ) : (
        <RouterProvider router={authRoute} />
      )}
    </div>
  );
}

export default Routes;
