import { MdDelete } from "react-icons/md";
import BackButton from "./BackButton";
import Colors from "../constants/Colors";
import { FiSave } from "react-icons/fi";

function BottomModal({
  onBack,
  id,
  onDelete,
  onSave,
  style,
  type = "button",
}: any) {
  return (
    <div
      className={`mt-8 grid lg:gap-2 lg:flex lg:flex-row-reverse lg:justify-between w-full pb-4 ${
        style ?? ""
      }`}
    >
      <div className="w-full flex justify-end gap-4 max-h-[55px]">
        <BackButton
          className="lg:w-1/3 w-full"
          title="Cancelar"
          onBack={onBack}
        />

        <button
          type={type}
          onClick={onSave}
          className="bg-gradient-to-r justify-center from-blue-500 to-green-500 w-full lg:w-1/3 flex py-4 px-4 rounded-3xl font-bold italic  "
          style={{
            color: Colors.white,
            background: `linear-gradient(to left,${Colors.background},${Colors.background} ,${Colors.background2} )`,
          }}
        >
          <div className="flex items-center gap-2">
            <FiSave size={20} /> Salvar
          </div>
        </button>
      </div>

      {id !== null && onDelete ? (
        <div className="mb-6 mt-2 lg:mt-0 lg:mb-0 w-full lg:w-1/5">
          <BackButton
            className="w-full bg-danger mr-10"
            title="Apagar"
            onClick={() => onDelete()}
            icon={<MdDelete size={20} />}
            background={Colors.danger}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default BottomModal;
