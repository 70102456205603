import React, { useState, useContext, useEffect, createContext } from "react";

import Strings from "../constants/Strings";
import { decodeToken } from "react-jwt";

import api from "../services/api";
import { toast } from "react-toastify";

const AuthContext: any = createContext<any>({});

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const getUser = () => {
    const storedToken = localStorage.getItem(Strings.token_jwt);

    if (storedToken) {
      const decodedToken = decodeToken(storedToken);

      return decodedToken;
    }
    return null;
  };

  useEffect(() => {
    setLoading(true);
    try {
      const decodedToken: any = getUser();
      setUser(decodedToken);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const response = await api.post("/profissionals/login", {
        email,
        password,
      });
      const token = response.data.token;
      setUser(token);

      localStorage.setItem(Strings.token_jwt, token);
    } catch (error: any) {
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error);
      }
    }
  };

  const logout = () => {
    localStorage.removeItem(Strings.token_jwt);
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        isLogged: user !== null,
        login,
        logout,
        getUser,
      }}
    >
      {!loading ? children : null}
    </AuthContext.Provider>
  );
};

// Crie um hook personalizado para acessar o contexto de autenticação
export const useAuth = () => useContext(AuthContext);
