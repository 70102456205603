import Modal from "react-modal";
import Colors from "../constants/Colors";
import Styles from "../constants/Styles";
import HeaderTitle from "./HeaderTitle";
import InputDefault from "./InputDefault";
import { useEffect, useState } from "react";
import BottomModal from "./BottomModal";
import exercicies from "../services/exercicies";

import { useAuth } from "../context/AuthContext";
import { usePrescription } from "../context/PrescriptionContext";

function PrescriptionExercicieModal({ isOpen, data, onBack }: any) {
  const [form, setForm] = useState<any>({ reps: 0, sets: 0, id: null });
  const [exercicieslist, setExercicieslist] = useState([]);
  const { getUser }: any = useAuth();
  const { saveExercicie }: any = usePrescription();

  const start = async () => {
    const ids = getUser()?.id;
    if (!ids) return;
    setExercicieslist(await exercicies.list_all(ids, 1, 10));
  };

  useEffect(() => {
    start();
    if (isOpen) {
      setForm(data);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onBack()}
      style={{
        content: Styles.styleModal,
      }}
      contentLabel="Example Modal"
    >
      <div className="max-h-[100vh]">
        <HeaderTitle title="Exercício" className="pl-4 pt-4" />
        <hr className="mt-4" />
        <form
          onSubmit={(e) => {}}
          className="grid w-[98vw] sm:w-[98vw] md:w-[100vw] lg:w-[50vw] h-[auto] sm:h-[auto] md:h-[auto] lg:h-[auto] p-4"
        >
          <div className="flex w-full">
            <div className="container">
              <div className="w-full grid gap-2 mt-4">
                <label
                  htmlFor="date_time"
                  className="text-md pl-1"
                  style={{ color: Colors.background }}
                >
                  Exercício
                </label>
                <select
                  id="id"
                  name="id"
                  onChange={({ target }) => {
                    setForm({ ...form, [target.name]: target.value });
                  }}
                  required
                  value={form.id}
                  className="appearance-none border-y-[1px] border-x-[1px] border-hrc rounded-3xl relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm"
                >
                  <option disabled selected />
                  {exercicieslist.map((e: any) => (
                    <option value={e.id}>{e.name}</option>
                  ))}
                </select>
              </div>

              <div className="w-full flex gap-4 justify-between">
                <InputDefault
                  label="Repetições"
                  id="reps"
                  name="reps"
                  type="number"
                  value={form.reps}
                  onChange={({ target }: any) => {
                    setForm({ ...form, [target.name]: target.value });
                  }}
                />

                <InputDefault
                  label="Séries"
                  id="sets"
                  name="sets"
                  type="number"
                  value={form.sets}
                  onChange={({ target }: any) => {
                    setForm({ ...form, [target.name]: target.value });
                  }}
                />
              </div>

              <div className="w-full flex gap-4 justify-between">
                <InputDefault
                  label="Intervalo"
                  id="interval"
                  name="interval"
                  type="text"
                  value={form.interval}
                  onChange={({ target }: any) => {
                    setForm({ ...form, [target.name]: target.value });
                  }}
                />

                <InputDefault
                  label="Carga"
                  id="charge"
                  name="charge"
                  type="text"
                  value={form.charge}
                  onChange={({ target }: any) => {
                    setForm({ ...form, [target.name]: target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="pb-2 p-4">
          <BottomModal
            onBack={onBack}
            id={form?.id}
            onSave={() => saveExercicie(form)}
          />
        </div>
      </div>
    </Modal>
  );
}
export default PrescriptionExercicieModal;
