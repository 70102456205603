import api from "./api";

async function listAll(professional_id: string) {
  try {
    const { data } = await api.get(
      `/professionals/${professional_id}/students`
    );
    return data.professional;
  } catch (e) {
    return {
      students: [],
    };
  }
}

async function renew(user_id: string, professional_id: string, date: Date) {
  try {
    const { data } = await api.post(`/professionals/renew_validity`, {
      professional_id,
      user_id,
      new_validity: date.toISOString(),
    });
    return data;
  } catch (e) {
    return {
      students: [],
    };
  }
}

async function prescription(prescription: any) {
  try {
    const { data } = await api.post(`/workout-plans`, prescription);
    return true;
  } catch (e) {
    return false;
  }
}

async function get_all_prescription(professional_id: string, user_id: string) {
  try {
    const { data } = await api.get(
      `/workout-plans/${professional_id}/${user_id}`
    );
    return data;
  } catch (e) {
    return [];
  }
}

async function create(body: any) {
  try {
    const { data } = await api.post(`/users/register`, body);
    return true;
  } catch (e) {
    return false;
  }
}

async function list_all_(user_id: any) {
  try {
    const { data } = await api.get(`/track/${user_id}`);
    return data;
  } catch (e) {
    return [];
  }
}

export default {
  listAll,
  renew,
  prescription,
  get_all_prescription,
  create,
  list_all_,
};
