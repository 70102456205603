export function DefaultTable({ className, data = [] }: any) {
  const tableHead = data.length > 0 ? Object.keys(data[0]) : [];

  return (
    <div
      className={
        "h-full w-full overflow-scroll bg-background p-2 rounded-lg " +
        className
      }
    >
      <table className="w-full table-auto text-left overflow-auto">
        <thead>
          <tr>
            {tableHead.map((head) => (
              <th
                key={head}
                className="border-b border-b-[2px] rounded border-whiteoff p-2"
              >
                <span color="blue-gray" className="font-bold">
                  {head.charAt(0).toUpperCase() + head.slice(1)}
                </span>
              </th>
            ))}
            <th className="border-b border-b-[2px] rounded border-whiteoff p-2"></th>
          </tr>
        </thead>

        <tbody>
          {data.map((row: any, index: number) => {
            const isLast = index === data.length - 1;
            const classes = isLast
              ? "p-4"
              : "p-4  border-b-[0.5px] rounded border-whiteoff";

            return (
              <tr key={index} className="w-full">
                {tableHead.map((key) => (
                  <td key={key} className={classes}>
                    <span color="blue-gray" className="font-light">
                      {row[key]}
                    </span>
                  </td>
                ))}
                <td className={classes}></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
