import utils from "../helpers/utils";
import api from "./api";

async function getWeekAppointment(user_id: string, date = new Date()) {
  try {
    const { data }: any = await api.get(
      `/appointments/week/${user_id}/${utils.getFormattedDate(date)}`
    );
    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
}

async function getDayAppointment(user_id: string, date = new Date()) {
  try {
    const { data } = await api.get(
      `/appointments/day/${user_id}/${utils.getFormattedDate(date)}`
    );
    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
}

async function cancelAppointment(appointment_id: string) {
  try {
    const { data } = await api.delete(`/appointments/${appointment_id}/cancel`);
    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
}

async function confrimAppointment(appointment_id: string) {
  try {
    const { data } = await api.post(`/appointments/${appointment_id}/approve`);
    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
}

async function createAppointment(body: any) {
  try {
    const { data } = await api.post(`/appointments/schedule`, body);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export default {
  getWeekAppointment,
  getDayAppointment,
  cancelAppointment,
  confrimAppointment,
  createAppointment,
};
