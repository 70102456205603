import Colors from "../constants/Colors";
import Strings from "../constants/Strings";
import utils from "../helpers/utils";

function StudentItem({ data, onClick }: any) {
  return (
    <div
      onClick={() => onClick(data)}
      className="rounded-2xl p-4 flex items-center gap-2 select-none cursor-pointer w-full shadow-lg"
      style={{
        background: `linear-gradient(to left, ${Colors.background},${Colors.background},${Colors.background},${Colors.background}, ${Colors.background2})`,
      }}
    >
      <img
        src={utils.getUriImage(
          data?.user?.photo != "" ? data?.user?.photo : Strings.default_photo
        )}
        style={{ width: 70, height: 70, objectFit: "cover" }}
        className="rounded"
      />
      <div className="flex flex-col justify-between gap-1">
        <span className="font-bold">{data?.user?.name}</span>
        <div className="flex flex-col">
          <span className="italic font-normal text-xs">
            {utils.calcularIdade(
              new Date(data?.user?.birth_date) ?? new Date()
            )}{" "}
            anos
          </span>
          <span className="italic font-normal text-xs mt-2">
            Validade:{" "}
            <span className="font-semibold">
              {utils.formatDate(new Date(data?.validity))}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default StudentItem;
