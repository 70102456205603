import { FiX } from "react-icons/fi";
import Colors from "../constants/Colors";

function BackButton({
  onClick,
  onBack,
  title,
  className,
  background,
  icon,
}: any) {
  return (
    <button
      type="button"
      className={
        "bg-gradient-to-r from-blue-500 to-green-500 flex justify-center py-4 px-4 rounded-3xl font-bold italic " +
        className
      }
      style={{
        color: Colors.white,
        background:
          background ??
          `linear-gradient(to left,${Colors.gray},${Colors.gray} ,${Colors.gray} )`,
      }}
      onClick={() => (onClick ? onClick() : onBack())}
    >
      <div className="flex items-center gap-2">
        {icon ?? <FiX size={20} />}
        {title ?? ""}
      </div>
    </button>
  );
}

export default BackButton;
