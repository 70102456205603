const atividadesComPersonalTrainer = [
  { id: 1, enum: "levantamento_peso", name: "Levantamento de peso", tempo: 60 },
  {
    id: 2,
    enum: "treinamento_funcional",
    name: "Treinamento funcional",
    tempo: 60,
  },
  { id: 3, enum: "crossfit", name: "Crossfit", tempo: 60 },
  {
    id: 4,
    enum: "treinamento_hiit",
    name: "Treinamento de alta intensidade (HIIT)",
    tempo: 60,
  },
  {
    id: 5,
    enum: "treinamento_resistencia",
    name: "Treinamento de resistência",
    tempo: 60,
  },
  {
    id: 6,
    enum: "treinamento_velocidade_agilidade",
    name: "Treinamento de velocidade e agilidade",
    tempo: 60,
  },
  {
    id: 7,
    enum: "treinamento_flexibilidade",
    name: "Treinamento de flexibilidade",
    tempo: 60,
  },
  {
    id: 8,
    enum: "treinamento_boxe",
    name: "Treinamento de técnicas de boxe",
    tempo: 60,
  },
  {
    id: 9,
    enum: "treinamento_artes_marciais",
    name: "Treinamento de técnicas de artes marciais",
    tempo: 60,
  },
  {
    id: 10,
    enum: "treinamento_competicoes",
    name: "Treinamento para competições esportivas",
    tempo: 60,
  },
  {
    id: 11,
    enum: "reabilitacao_fisica",
    name: "Reabilitação física",
    tempo: 60,
  },
  {
    id: 12,
    enum: "treinamento_grupos_especificos",
    name: "Treinamento para grupos específicos (idosos, gestantes, crianças)",
    tempo: 60,
  },
  {
    id: 13,
    enum: "treinamento_cardiovascular",
    name: "Treinamento cardiovascular",
    tempo: 60,
  },
  {
    id: 14,
    enum: "treinamento_equilibrio_coordenacao",
    name: "Treinamento de equilíbrio e coordenação",
    tempo: 60,
  },
  {
    id: 15,
    enum: "acompanhamento_nutricao",
    name: "Acompanhamento de dieta e nutrição",
    tempo: 60,
  },
  {
    id: 16,
    enum: "avaliacao_planos_treinamento",
    name: "Avaliação física e elaboração de planos de treinamento",
    tempo: 60,
  },
  {
    id: 17,
    enum: "monitoramento_progresso",
    name: "Monitoramento de progresso e ajuste de rotinas",
    tempo: 60,
  },
  {
    id: 18,
    enum: "treinamento_postura_correcao",
    name: "Treinamento de postura e correção de movimentos",
    tempo: 60,
  },
  {
    id: 19,
    enum: "treinamento_prevencao_lesoes",
    name: "Treinamento de prevenção de lesões",
    tempo: 60,
  },
  { id: 20, enum: "treinamento_core", name: "Treinamento de core", tempo: 60 },
  {
    id: 21,
    enum: "treinamento_estabilidade",
    name: "Treinamento de estabilidade",
    tempo: 60,
  },
];

const Texts = {
  email_end: "Email",
  password: "Senha",
  entrar: "Entrar",
  esqueceu_senha: "Esqueceu sua senha?",
  atividadesComPersonalTrainer: atividadesComPersonalTrainer,
};

export default Texts;
