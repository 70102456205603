import { FiLoader, FiPlus } from "react-icons/fi";
import Colors from "../constants/Colors";

function Button({ title, icon, className, onClick, type, loading }: any) {
  return (
    <button
      type={type ?? "button"}
      disabled={loading}
      onClick={onClick}
      className={
        "bg-gradient-to-r w-full from-blue-500 to-green-500 flex justify-center py-4 px-3 rounded-3xl font-bold italic " +
        className
      }
      style={{
        color: Colors.white,
        background: `linear-gradient(to left,${Colors.background},${Colors.background} ,${Colors.background2} )`,
      }}
    >
      {!loading ? (
        <div className="flex items-center gap-2">
          {icon ?? <FiPlus size={20} />}
          {title ?? ""}
        </div>
      ) : (
        <div className="flex items-center gap-2">
          <FiLoader size={20} /> {title ?? ""}
        </div>
      )}
    </button>
  );
}

export default Button;
