import api from "./api";

async function get_plan(user_id: any) {
  try {
    const { data } = await api.get(`/plans/${user_id}`);
    return data;
  } catch (e) {
    return {};
  }
}

export default { get_plan };
